import { types } from 'mobx-state-tree';
import { Identifier } from './line-identifier';
import { Pour } from './pour';

const HistoricalStatisticModel = types.model({
  duration: types.model({
    avg: types.maybeNull(types.string),
    median: types.maybeNull(types.string),
    n: types.maybeNull(types.number),
    stdevp: types.maybeNull(types.string),
  }),
  interval: types.model({
    avg: types.maybeNull(types.string),
    median: types.maybeNull(types.string),
    n: types.maybeNull(types.number),
    stdevp: types.maybeNull(types.string),
  }),
});

const PoursStatisticModel = types.model({
  n_pours: types.maybeNull(types.number),
  sensor_temp_c: types.model({
    max: types.maybeNull(types.number),
    stdevp: types.maybeNull(types.number),
  }),
});

export const LinesStatistics = types.model({
  id: types.integer,
  establishment_id: types.integer,
  archived: types.boolean,
  // metadata: null,
  identifiers: types.maybeNull(Identifier),
  line_identifier: types.maybeNull(types.string),
  // tap_identifier: null,
  status_code: types.number,
  scheduled_cleaning_at: types.maybeNull(types.string),
  sort_value: types.number,
  cooler_id: types.maybeNull(types.integer),
  current_cleaning_id: types.maybeNull(types.integer),
  // cleaning_statistics_effective_from: null,
  fob_installed: types.maybeNull(types.boolean),
  displacement_m: types.maybeNull(types.string),
  _volume_ml: types.maybeNull(types.string),
  _item_lines_latest_item_id: types.maybeNull(types.number),
  _pours_latest_pour: types.maybeNull(Pour),
  _cleanings_cleaned_from_latest: types.maybeNull(types.string),
  _cleanings_cleaned_to_latest: types.maybeNull(types.string),
  _cleanings_historical_statistics: types.maybeNull(HistoricalStatisticModel),
  _cleanings_latest_pours_statistics: types.maybeNull(PoursStatisticModel),
});
