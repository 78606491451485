import { useCallback, useMemo } from 'react';
import { useStore } from 'hooks';

const usePourDetection = () => {
  const { topologyManagementStore } = useStore();

  const detectedPours = useMemo(() => {
    return topologyManagementStore.pours;
  }, [topologyManagementStore.pours]);

  const clearPours = useCallback(() => {
    topologyManagementStore.resetRecentPours();
  }, [topologyManagementStore]);

  return { detectedPours, hasDetectedPours: detectedPours.length > 0, clearPours };
};

export default usePourDetection;
