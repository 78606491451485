import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Box, Typography, Button, IconButton, Drawer, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMediaQueries, useStore, useToast } from 'hooks';

import { dateUtilities } from 'utils';
import { messages } from 'config/messages';

import Loader from 'components/shared/Loader';
import { details } from '../../settings';

const FullDetails = ({ type = null, open, onClose }) => {
  const { isTabletOrSmaller } = useMediaQueries();
  const { errorToast, successToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { userStore, subscriptionsStore } = useStore();
  const [demoRequestTime, setDemoRequestTime] = useState('');

  const subscriptionType = type || location?.state?.type || 'smartDraft';

  const handleBack = () => {
    navigate('/system-configuration/subscription');
  };

  const onRequestDemo = async () => {
    if (!subscriptionsStore.demoRequestSentRecently) {
      try {
        await subscriptionsStore.requestDemoSubscription({ subscriptionType });
        successToast(messages.common.success);
      } catch (error) {
        console.error(error);
        errorToast(error.response.data.error.message);
      }
    }
  };

  useEffect(() => {
    let intervalDemoId;

    if (subscriptionsStore.demoRequestSentRecently(subscriptionType)) {
      intervalDemoId = setInterval(() => {
        setDemoRequestTime(
          dateUtilities.calculateRemainingTime(
            subscriptionsStore.demoRequestSentRecently(subscriptionType),
          ),
        );
      }, 1000);
    }

    return () => clearInterval(intervalDemoId);
  }, [subscriptionType, subscriptionsStore, subscriptionsStore.demoRequestSentRecently]);

  const tooltipText = useMemo(
    () =>
      !!subscriptionsStore.demoRequestSentRecently(subscriptionType)
        ? `Next Demo request will be available in: ${demoRequestTime}`
        : '',
    [demoRequestTime, subscriptionType, subscriptionsStore],
  );

  const desktop = (
    <StyledRootBox>
      <Box className="header">
        <Box className="title_box">
          <IconButton className="icon" onClick={handleBack}>
            <ArrowBackIosNew />
          </IconButton>
          <Box>
            <Typography className="title">
              {subscriptionsStore.subscriptionNameByType(subscriptionType)}
            </Typography>
            <Typography className="subtitle">{details[subscriptionType]?.subtitle}</Typography>
          </Box>
        </Box>
        <Tooltip title={tooltipText}>
          <Button
            variant="outlined"
            className="demo_button"
            onClick={onRequestDemo}
            disabled={
              !['Owner', 'BarTrack'].includes(userStore.currentRole?._role_name) ||
              subscriptionsStore.isLoading ||
              !!subscriptionsStore.demoRequestSentRecently
            }
          >
            {subscriptionsStore.isLoading ? <Loader size="14px" /> : 'Request Demo'}
          </Button>
        </Tooltip>
      </Box>
      <Box className="body">
        <Box
          className="image"
          sx={{ backgroundImage: `url(${details[subscriptionType]?.image})` }}
        />
        <Typography sx={({ palette }) => ({ color: palette.text.secondary, fontSize: '14px' })}>
          {details[subscriptionType]?.description}
        </Typography>

        <Typography sx={({ palette }) => ({ color: palette.whiteEmphasis.high, fontSize: '20px' })}>
          Key Features
        </Typography>

        <Box className="feature_box">
          {details[subscriptionType]?.features?.map(item => (
            <Box className="feature_item" key={item.title}>
              {item.icon}
              <Typography variant="h5" align="center">
                {item.title}
              </Typography>
              <Typography
                sx={({ palette }) => ({ color: palette.text.secondary, fontSize: '14px' })}
              >
                {item.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </StyledRootBox>
  );

  const mobile = (
    <StylerRootDrawer open={open} onClose={onClose} anchor="right">
      <Box className="header">
        <IconButton className="back_arrow_button" onClick={onClose}>
          <ArrowForwardIos />
        </IconButton>
        <Typography variant="h7">
          {subscriptionsStore.subscriptionNameByType(subscriptionType)}
        </Typography>
      </Box>
      <Box className="body">
        <img alt="" src={details[subscriptionType]?.image} />
        <Typography className="title">
          {subscriptionsStore.subscriptionNameByType(subscriptionType)}
        </Typography>
        <Typography className="subtitle">{details[subscriptionType]?.subtitle}</Typography>
        <Typography sx={({ palette }) => ({ color: palette.text.secondary, fontSize: '14px' })}>
          {details[subscriptionType]?.description}
        </Typography>
        <Typography sx={({ palette }) => ({ color: palette.whiteEmphasis.high, fontSize: '20px' })}>
          Key Features
        </Typography>
        <Box className="feature_box">
          {details[subscriptionType]?.features.map(item => (
            <Box className="feature_item" key={item.title}>
              {item.icon}
              <Typography variant="h5" align="center">
                {item.title}
              </Typography>
              <Typography
                sx={({ palette }) => ({ color: palette.text.secondary, fontSize: '14px' })}
              >
                {item.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ padding: '16px' }}>
        <Button
          variant="outlined"
          className="demo_button"
          onClick={onRequestDemo}
          disabled={
            !['Owner', 'BarTrack'].includes(userStore.currentRole?._role_name) ||
            subscriptionsStore.isLoading ||
            !!subscriptionsStore.demoRequestSentRecently
          }
        >
          {subscriptionsStore.isLoading ? <Loader size="14px" /> : 'Request Demo'}
        </Button>
        <Typography
          className={clsx('request_timer', {
            hidden: !subscriptionsStore.demoRequestSentRecently,
          })}
        >
          {`Next Demo request will be available in: ${demoRequestTime}`}
        </Typography>
      </Box>
    </StylerRootDrawer>
  );

  return isTabletOrSmaller ? mobile : desktop;
};

export default observer(FullDetails);

const StyledRootBox = styled(Box)(({ theme: { palette, breakpoints, spacing } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  '.header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '.title_box': {
      display: 'flex',
      flexDirection: 'row',
      columnGap: spacing(1),
    },

    '.title': {
      fontSize: '26px',
      fontWeight: 700,
      color: palette.whiteEmphasis.high,
    },
    '.subtitle': {
      fontSize: '14px',
      fontWeight: 500,
      color: palette.whiteEmphasis.high,
    },

    '.icon': {
      marginTop: '2px',
      width: '30px',
      height: '30px',
    },

    '.demo_button': {
      textTransform: 'none',
      backgroundColor: 'transparent',
      border: `1px solid ${palette.primary.main}`,
      minWidth: '200px',

      '&.Mui-disabled': {
        pointerEvents: 'auto',
      },
    },
  },

  '.body': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(2),

    '.image': {
      width: '100%',
      height: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',

      [breakpoints.down('md')]: {
        height: '166px',
      },
    },

    '.feature_box': {
      display: 'flex',
      flexDirection: 'row',
      columnGap: spacing(2),
    },

    '.feature_item': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: spacing(2),
      backgroundColor: palette.secondary.greyLight,
      borderRadius: '12px',
      padding: `${spacing(3)} ${spacing(2)}`,
    },

    '.feature_icon': {
      margin: '0 auto',
      width: '48px',
      height: '48px',
    },
  },
}));

const StylerRootDrawer = styled(Drawer)(({ theme: { palette, spacing } }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    padding: '16px',
    backgroundColor: palette.secondary.dark,
  },

  '.header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
  },

  '.back_arrow_button': {
    transform: 'rotate(180deg)',
    position: 'absolute',
    left: '16px',

    '& svg': {
      fill: palette.whiteEmphasis.medium,
      width: '24px',
      height: '24px',
    },
  },

  '.body': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(2),
    overflow: 'auto',

    '.title': {
      fontSize: '26px',
      fontWeight: 700,
      color: palette.whiteEmphasis.high,
    },
    '.subtitle': {
      fontSize: '14px',
      fontWeight: 500,
      color: palette.whiteEmphasis.high,
    },

    '.image': {
      width: '100%',
      height: '165px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
    },

    '.feature_box': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: spacing(2),
    },

    '.feature_item': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: spacing(2),
      backgroundColor: palette.secondary.greyLight,
      borderRadius: '12px',
      padding: `${spacing(3)} ${spacing(2)}`,
    },

    '.feature_icon': {
      margin: '0 auto',
      width: '48px',
      height: '48px',
    },
  },

  '.demo_button': {
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: `1px solid ${palette.primary.main}`,
    width: '100%',
  },

  '.request_timer': {
    fontSize: '12px',
    color: palette.whiteEmphasis.medium,
    textAlign: 'center',
    padding: `0 32px`,
    marginTop: '8px',

    '&.hidden': {
      visibility: 'hidden',
    },
  },
}));
