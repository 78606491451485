import { types } from 'mobx-state-tree';

const metadataModel = types.model({
  original_sensor_name: types.maybeNull(types.string),
});

const troubleshootingModel = types.model({
  steps: types.maybeNull(types.array(types.string)),
  educational_details: types.maybeNull(types.string),
});

export const Alarm = types.model({
  active: types.boolean,
  alert_caption: types.string,
  archived: types.boolean,
  default_alert_caption: types.maybeNull(types.string),
  default_metadata: types.frozen(),
  default_rule: types.maybeNull(types.frozen()),
  establishment_id: types.maybeNull(types.integer),
  id: types.integer,
  metadata: metadataModel,
  operand_id: types.maybeNull(types.integer),
  prototype_alarm_id: types.maybeNull(types.integer),
  role_users_ids: types.maybeNull(types.array(types.number)),
  role_users_ids_muted: types.maybeNull(types.array(types.number)),
  rule: types.string,
  type: types.string,
  _alert_caption_is_default: types.boolean,
  _gateways_id: types.maybeNull(types.number),
  _metadata_is_default: types.boolean,
  _rule_is_default: types.boolean,
  troubleshooting: types.maybeNull(troubleshootingModel),
});
