import { AutoGraph, CloudDoneOutlined, NotificationsOutlined } from '@mui/icons-material';
import {
  InventoryOutlinedIcon,
  DataTableIcon,
  BeerSubscriptionIcon,
  GraphAltIcon,
  Tap2Icon,
  FridgeWhiteIcon,
} from 'assets/icons';

const text = {
  smartMonitoringCooling: {
    subscription: {
      description: {
        default:
          'Protect your investment with real-time tracking of coolers, glycol systems, and essential equipment, ensuring peak beer quality and seamless operation.',
        active: 'Smart Refrigeration & Coolers, Smart Kegerator, and Smart Glycol Power Pack',
      },
    },
    details: {
      description:
        'Protect your investment with Glycol Monitoring, offering comprehensive real-time tracking of your coolers, glycol systems, and other essential equipment. Receive push notifications for temperature fluctuations, system anomalies, and maintenance needs, ensuring your beer stays at its peak quality. Click below to learn how Glycol Monitoring gives you peace of mind and keeps your system running smoothly.',
    },
  },

  smartDraft: {
    subscription: {
      description: {
        default:
          'Optimize your draft beer operations with real-time insights into inventory, pour consistency, and loss prevention, ensuring fresh beer and maximized profits.',
        active: 'Monitor keg levels, line temperature and pressure in real-time.',
      },
    },
    details: {
      description:
        'Discover how Beverage Monitoring optimizes your draft beer operations by providing real-time insights into your inventory, pour consistency, and loss prevention. With accurate tracking of every ounce, our system ensures you serve the freshest beer while maximizing your profit margins. Learn how Beverage Monitoring integrates seamlessly into your workflow, transforming how you manage draft beer.',
    },
  },
};

const details = {
  smartMonitoringCooling: {
    image: '/assets/images/smart_monitoring_image.png',
    subtitle: 'Complete IoT Monitoring for Your System',
    icon: <FridgeWhiteIcon className="icon" />,
    description:
      'Protect your investment with Glycol Monitoring, offering comprehensive real-time tracking of your coolers, glycol systems, and other essential equipment. Receive push notifications for temperature fluctuations, system anomalies, and maintenance needs, ensuring your beer stays at its peak quality. Click below to learn how Glycol Monitoring gives you peace of mind and keeps your system running smoothly.',
    features: [
      {
        icon: <GraphAltIcon className="feature_icon" />,
        title: 'Real-Time Monitoring and Alerts',
        description:
          'Stay ahead of issues with 24/7 monitoring of your coolers, glycol systems, and storage areas. Receive instant push notifications for temperature fluctuations, equipment malfunctions, or environmental changes.',
      },
      {
        icon: <DataTableIcon className="feature_icon" />,
        title: 'Data-Driven Insights',
        description:
          'Leverage actionable insights through automated data reporting, helping you optimize energy usage, prevent costly downtime, and maintain consistent beer quality.',
      },
      {
        icon: <CloudDoneOutlined className="feature_icon" />,
        title: 'Cloud-Based Accessibility',
        description:
          'Access your system’s data from anywhere with cloud-based monitoring, allowing you to stay in control even when off-site.',
      },
      {
        icon: <NotificationsOutlined className="feature_icon" />,
        title: 'Customizable Thresholds and Alerts',
        description:
          'Tailor alert thresholds to meet the unique needs of your operation, ensuring notifications are only sent for the most critical events.',
      },
    ],
  },
  smartDraft: {
    subtitle: 'Revolutionizing Draft Beer Management',
    icon: <Tap2Icon className="icon" />,
    description:
      'Discover how Beverage Monitoring optimizes your draft beer operations by providing real-time insights into your inventory, pour consistency, and loss prevention. With accurate tracking of every ounce, our system ensures you serve the freshest beer while maximizing your profit margins. Learn how Smart Draft integrates seamlessly into your workflow, transforming how you manage draft beer.',
    image: '/assets/images/smart_draft_image.png',
    features: [
      {
        icon: <BeerSubscriptionIcon className="feature_icon" />,
        title: 'Optimize Draft Quality',
        description:
          'Ensure you are providing the best drinking experience to your customers by monitoring variables such as temperature, pressure, line cleanliness, and draft system health.',
      },
      {
        icon: <DataTableIcon className="feature_icon" />,
        title: 'Data-Driven Insights',
        description:
          'Harness actionable data with automated reports fueled by key performance indicators, empowering strategic planning for enhanced beverage cost and operational efficiencies. ',
      },
      {
        icon: <InventoryOutlinedIcon className="feature_icon" />,
        title: 'Streamline Inventory',
        description:
          "View your real-time keg levels anywhere with the BarTrack App. Optimize re-ordering by tracking the rate of consumption, so you'll never shake or weigh a keg again!",
      },
      {
        icon: <AutoGraph className="feature_icon" />,
        title: 'Boost Profits',
        description:
          'Through POS integration, we identify and prevent every source of waste with time-stamped pour-by-pour analysis. From foam to unrung pours, now you can proactively prevent waste and increase your draft profits.',
      },
    ],
  },
};

export { text, details };
