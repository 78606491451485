import React, { useCallback, useState, useMemo, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { Box, Button, Typography, styled } from '@mui/material';

import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';

import { PullTapIcon } from 'assets/icons';
import { useStore, usePourDetection } from 'hooks';

import { CalibrationContext } from 'context/calibration';
import NotDetected from './NotDetected';
import PourDetected from './PourDetected';

const PourDetection = ({
  sensor,
  isError,
  goToStep,
  handleClose,
  setSelectedSensor,
  setIsError,
}) => {
  const { setAccuracy } = useContext(CalibrationContext);
  const { topologyManagementStore } = useStore();
  const { hasDetectedPours, detectedPours, clearPours } = usePourDetection();

  const {
    line: { identifiers, beverage },
  } = sensor;
  const bru = topologyManagementStore.getBruBySensorId(sensor?.id);

  const [detectState, setDetectState] = useState({
    currentSensorDetected: false,
    anotherLineDetected: false,
    multipleLinesDetected: false,
    notDetected: false,
  });
  const [anotherSensor, setAnotherSensor] = useState(null);
  const [calibrationAttempts, setCalibrationAttempts] = useState(1);

  const resetState = useCallback(() => {
    setCalibrationAttempts(prev => {
      prev++;
    });
    setDetectState({
      anotherLineDetected: false,
      multipleLinesDetected: false,
      notDetected: false,
    });
    setIsError(false);
    clearPours();
  }, [clearPours, setIsError]);

  if (
    !hasDetectedPours &&
    (detectState.currentSensorDetected ||
      detectState.anotherLineDetected ||
      detectState.multipleLinesDetected)
  ) {
    resetState();
  }

  const isPourOnCurrentSensor = pour => {
    return (
      sensor._gateways_id === Number(pour.gateway_id) &&
      bru.gateway_bru_address === pour.bru_addr &&
      sensor.bru_sensor_address === pour.sensor_addr
    );
  };

  const currentLineTitle = useMemo(
    () => `Line ${identifiers.line.numerical} (${beverage?._name || 'N/A'})`,
    [identifiers, beverage],
  );

  if (hasDetectedPours) {
    if (detectedPours.length === 1) {
      if (isPourOnCurrentSensor(detectedPours[0])) {
        if (!detectState.currentSensorDetected) {
          setDetectState(prev => ({
            ...prev,
            currentSensorDetected: true,
            anotherLineDetected: false,
            multipleLinesDetected: false,
          }));
        }
      } else if (!detectState.anotherLineDetected) {
        setAnotherSensor(
          topologyManagementStore.getSensorByNestedAddress({
            gatewayId: Number(detectedPours[0].gateway_id),
            bruAddress: detectedPours[0].bru_addr,
            sensorAddress: detectedPours[0].sensor_addr,
          }),
        );
        setDetectState(prev => ({
          ...prev,
          anotherLineDetected: true,
          multipleLinesDetected: false,
        }));
      }
    } else {
      if (!detectState.multipleLinesDetected) {
        setDetectState(prev => ({
          ...prev,
          anotherLineDetected: false,
          multipleLinesDetected: true,
        }));
      }
    }
  }

  const nothingHappenHandler = useCallback(() => {
    setIsError(true);
    setDetectState(prev => ({ ...prev, notDetected: true }));
  }, [setIsError]);

  const onRestart = useCallback(() => {
    resetState();
    setSelectedSensor(anotherSensor);
    topologyManagementStore.setSelectedLines([anotherSensor?.line.id]);
    goToStep('BeforeBegin');
  }, [goToStep, resetState, setSelectedSensor, anotherSensor, topologyManagementStore]);

  const tryAgainHandler = useCallback(() => {
    resetState();
  }, [resetState]);

  const handleContinue = useCallback(() => {
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    // Reset Accuracy calibration before start new one
    setAccuracy({
      level: 'N/A',
      value: null,
      percentage: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError && detectState.notDetected) {
    return <NotDetected onTryAgain={tryAgainHandler} />;
  }

  if (detectState.currentSensorDetected) {
    return (
      <PourDetected
        calibrationAttempts={calibrationAttempts}
        sensor={sensor}
        bru={bru}
        detectedPour={detectedPours[0]}
        handleBack={tryAgainHandler}
        handleContinue={handleContinue}
      />
    );
  }

  const hasWarning = detectState.anotherLineDetected || detectState.multipleLinesDetected;

  let title = 'Awaiting pour...';
  let subtitles = [
    `In one continuous pour, go to <b>${currentLineTitle}</b> and pour a few ounces of product into the glass.`,
  ];
  let buttons = [{ label: 'I did but nothing happened', onClick: nothingHappenHandler }];

  if (detectedPours.length === 1 && detectState.anotherLineDetected) {
    title = 'Pour detected from another sensor!';
    subtitles = [
      `Pour detected on <b>BRU #${detectedPours[0].bru_addr}, Sensor #${detectedPours[0].sensor_addr}</b>`,
      `It appears the pour was from a different line than expected. Would you like to go back and continue waiting for <b>${currentLineTitle}</b>, or proceed with the detected line?`,
    ];
    buttons = [
      {
        label: `Return to `,
        afterLabel: currentLineTitle,
        onClick: tryAgainHandler,
      },
      {
        label: `Restart with `,
        afterLabel: `BRU #${detectedPours[0].bru_addr} (Sensor #${detectedPours[0].sensor_addr})`,

        onClick: onRestart,
      },
    ];
  } else if (detectedPours.length > 1 && detectState.multipleLinesDetected) {
    title = 'Multiple sensors detected!';
    subtitles = [
      `Multiple pours from multiple Sensors detected.`,
      `Please ensure that nobody else is actively pouring while you are calibrating and ensure you're pouring from the correct line.`,
    ];
    buttons = [{ label: 'Try again', onClick: tryAgainHandler }];
  }

  return (
    <StyledRootBox>
      <Box className="main_box">
        <Box className={clsx('warning_box', { hidden: !hasWarning })}>
          <WarningAmberIcon />
        </Box>
        <PullTapIcon className="pull_tap_icon" />
        <Typography className="title">{title}</Typography>
      </Box>
      <Box className="subtitle_box">
        {subtitles.map((text, index) => (
          <Typography key={index} className="subtitle" dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </Box>

      <Box className={clsx('action_box', { wide: detectState.anotherLineDetected })}>
        {buttons.map(({ label, afterLabel, onClick }, index) => (
          <Button
            key={index}
            className={clsx('wide', { btn: index === 0 })}
            variant={index ? 'contained' : 'outlined'}
            onClick={onClick}
            {...(index ? { color: 'primary' } : {})}
          >
            {label} &nbsp; {detectState.anotherLineDetected && <b>{afterLabel}</b>}
          </Button>
        ))}
      </Box>
    </StyledRootBox>
  );
};

export default observer(PourDetection);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',
  // height: '504px',

  '.title': {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    color: palette.whiteEmphasis.medium,
    marginTop: '16px',
  },

  '.subtitle': {
    fontSize: '12px',
    color: palette.whiteEmphasis.high,
    marginTop: '12px',
    textAlign: 'center',
  },

  '.warning_icon': {
    width: '40px',
    height: '40px',
    color: palette.status.red,
  },

  '.warning_title': {
    fontSize: '14px',
    fontWeight: '500',
    color: palette.status.red,
  },

  '.main_box': {
    padding: '24px',
    margin: '24px 0',
    border: `1px solid #313131`,
    backgroundColor: '#252525',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '&.row': {
      height: '147px',
      flexDirection: 'row',
      padding: '24px',
      justifyContent: 'space-between',
      '.title_wrapper': {
        width: '50%',
        '.title': {
          fontSize: '14px',
          fontWeight: '500',
          textAlign: 'left',

          color: palette.whiteEmphasis.high,
        },
        '.subtitle': {
          fontSize: '12px',
          color: palette.whiteEmphasis.high,
          marginTop: '12px',
          textAlign: 'left',
        },
      },
    },
  },

  '.subtitle_box': {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.bru_line_schema_wrapper': {
    width: '112px',
    height: '95px',
  },

  '.pull_tap_icon': {
    width: '88px',
    height: '88px',
  },

  '.warning_box': {
    position: 'absolute',
    transform: 'translate(34px, -50px)',
    width: '24px',
    height: '24px',

    '&.hidden': {
      visibility: 'hidden',
    },

    '& > svg': {
      color: palette.primary.main,
    },
  },

  '.red': {
    color: palette.status.red,
  },

  '.action_box': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '24px 0',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid #313131`,

    '&.wide': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '12px',
    },

    button: {
      textTransform: 'none',
      width: 'calc(100% - 40px)',
      height: '40px',

      '&.btn': {
        backgroundColor: '#282828',
        color: palette.whiteEmphasis.high,
      },
    },
  },
}));
