import React from 'react';
import { observer } from 'mobx-react';
import { Box, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useMediaQueries, useStore } from 'hooks';
import Desktop from 'components/shared/Desktop';
import Mobile from 'components/shared/Mobile';
import DesktopHeader from './components/DesktopHeader';
import MobileHeaderPlaceholder from './components/MobileHeaderPlaceholder';
import NotificationBar from 'components/shared/NotificationBar';
import TopNavigation from '../TopNavigation';
import clsx from 'clsx';

import { HEADER_HEIGHT, BIG_HEADER_HEIGHT } from 'config/ui';

const Header = () => {
  const { isDesktopOrGreater } = useMediaQueries();
  const { ui } = useStore();
  const location = useLocation();
  const { pathname } = location;

  if (pathname) {
    ui.setPathState(pathname);
  }

  const match =
    ['/on-tap', '/inventory', '/inventory/cooler', '/inventory/ordering'].includes(
      location.pathname,
    ) && !Boolean(location?.state?.id);

  return (
    <StyledRootBox
      className={clsx({ system_offline: !isDesktopOrGreater && ui.systemStatus.offline })}
    >
      <NotificationBar />
      <Box
        display="flex"
        className="header_wrapper"
        sx={{ height: { xs: !!match ? BIG_HEADER_HEIGHT : HEADER_HEIGHT, md: HEADER_HEIGHT } }}
      >
        <Desktop>
          <DesktopHeader />
        </Desktop>
        <Mobile>
          <Box className="header_content">
            <Box className="header">
              <MobileHeaderPlaceholder />
            </Box>
            <TopNavigation />
          </Box>
        </Mobile>
      </Box>
    </StyledRootBox>
  );
};

export default observer(Header);

const StyledRootBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1200,
  width: '100%',
  background: theme.palette.secondary.main,

  '&.system_offline': {
    '.top_navigation': {
      top: 100,
    },
  },

  '.header_wrapper': {
    width: '100%',
  },
  '.header_content': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  '.header': {
    position: 'relative',
    padding: `0 ${theme.spacing(3)}`,
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down(400)]: {
      padding: `0 ${theme.spacing(1)}`,
    },
    '.top_navigation': {
      padding: 0,
      width: '100%',
    },
  },
}));
