import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { Container } from './types/';
import containerTypes from 'config/containerTypes';
import { getRootStore } from 'models/root';

export const containersInitialState = {
  all: [],
  isLoaded: false,
};

const ContainerWithView = Container.views(self => ({
  get volume_unit() {
    const { unitStore } = getRootStore();
    return unitStore.getById(self.volume_unit_id);
  },
  get beveragesWithSKU() {
    const root = getRootStore();
    const type = self.type_code === 4 ? 1 : 0;
    const skuses = root.skuStore.all
      .filter(e => e.type_code === type)
      .filter(e => e.container_id === self.id);
    if (skuses && skuses.length) {
      const ids = skuses.map(s => s.beverage_id);
      const beverages = root.beveragesStore.all.filter(bev => ids.includes(bev.id));
      return { skuses, beverages };
    }
    return { skuses, beverages: [] };
  },
}));

export const containersModel = types
  .model({
    all: types.array(ContainerWithView),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get containers() {
      return self.all.filter(container => container.type_code !== 4);
    },

    get draftContainers() {
      return self.all.filter(
        container =>
          container.establishment_id === self.currentEstablishmentId &&
          container.type_code === containerTypes.Draft,
      );
    },

    get allDraftPrototypes() {
      return self.all.filter(
        container =>
          container.establishment_id === 0 && container.type_code === containerTypes.Draft,
      );
    },

    get availableDraftPrototypes() {
      return self.allDraftPrototypes.filter(
        prototype => !self.draftContainers.some(c => c.name === prototype.name && !c.archived),
      );
    },

    get kegContainers() {
      const root = getRootStore();
      const establishmentId = root.userStore.currentRole._establishment_id;

      return self.all.filter(
        container =>
          container.establishment_id === establishmentId &&
          container.type_code === containerTypes.Keg,
      );
    },

    getKegContainerNameByID: id => self.kegContainers.find(c => c.id === id)?.name,

    getKegContainerByID: id => self.kegContainers.find(c => c.id === id),

    kegContainersByIDs(IDs) {
      return self.kegContainers.filter(container => IDs.some(id => Number(id) === container.id));
    },

    getContainerByID(id) {
      return self.all.find(container => Number(id) === container.id);
    },

    get allKegPrototypes() {
      return self.all.filter(
        container => container.establishment_id === 0 && container.type_code === containerTypes.Keg,
      );
    },

    get availableKegPrototypes() {
      return self.allKegPrototypes.filter(
        prototype => !self.kegContainers.some(c => c.name === prototype.name && !c.archived),
      );
    },

    get currentEstablishmentId() {
      const root = getRootStore();
      return root.userStore.currentRole._establishment_id;
    },

    get availableDraftContainers() {
      return self.draftContainers.filter(cont => cont.metadata && cont.metadata.display_status);
    },

    get sortedAvailableDraftContainers() {
      return self.availableDraftContainers.sort((a, b) => {
        if (
          a.volume_unit &&
          b.volume_unit &&
          a.volume_unit.ml_equivalent &&
          b.volume_unit.ml_equivalent &&
          a.volume_total &&
          b.volume_total
        ) {
          return (
            a.volume_total * a.volume_unit.ml_equivalent -
            b.volume_total * b.volume_unit.ml_equivalent
          );
        } else if (a.volume_total && b.volume_total) {
          return a.volume_total - b.volume_total;
        }
        return 0;
      });
    },

    get availableKegContainers() {
      return self.kegContainers.filter(cont => cont.metadata && cont.metadata.display_status);
    },

    getAvailableContainersForBeverage(exists_skuses) {
      const ids = exists_skuses.map(skus => skus.container_id);
      return self.availableKegContainers.filter(container => !ids.includes(container.id));
    },

    getAvailableDraftContainersForBeverage(beverageId) {
      const { skuStore } = getRootStore();
      const beverageSKUs = skuStore.getDraftSKUsByBeverageId(beverageId);
      const ids = beverageSKUs.map(skus => skus.container_id);
      return self.availableDraftContainers.filter(container => !ids.includes(container.id));
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* () {
        try {
          const prototypesEstablishmentId = 0;
          const containersData = yield api.getContainers({
            establishment_id: [prototypesEstablishmentId, self.currentEstablishmentId],
            type_code: [containerTypes.Keg, containerTypes.Draft],
          });

          const fetchedContainers = containersData.data.result.map(container => ({
            ...container,
            volume_unit: container.volume_unit_id,
          }));
          self.all.replace(fetchedContainers);
          self.isLoaded = true;
          return self.all;
        } catch (err) {
          self.all.replace([]);
          self.isLoaded = false;

          return Promise.reject(err);
        }
      }),

      update: flow(function* (id, patch) {
        try {
          const response = yield api.patchContainer(
            patch,
            { establishment_id: self.currentEstablishmentId },
            { id },
          );

          if (patch.archived === true) {
            const item = self.all.find(c => c.id === id);
            self.all.remove(item);
          } else {
            const itemIndex = self.all.findIndex(c => c.id === id);
            self.all[itemIndex] = {
              ...response.data.row,
              volume_unit: response.data.row.volume_unit_id,
            };
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      create: flow(function* (data) {
        try {
          const response = yield api.createContainer({
            ...data,
            establishment_id: self.currentEstablishmentId,
            status_code: 0,
          });
          const container = { ...response.data.row, volume_unit: response.data.row.volume_unit_id };
          self.all.push(container);
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      delete: flow(function* (id) {
        try {
          const response = yield api.deleteContainer(id);

          const item = self.all.find(c => c.id === id);
          self.all.remove(item);

          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateContainerById(updatedContainer) {
        self.all.replace(
          self.all
            .map(container => {
              if (container.id === updatedContainer.id) {
                return { ...updatedContainer, volume_unit: updatedContainer.volume_unit_id };
              } else {
                return container;
              }
            })
            .filter(container => !container.archived),
        );
      },

      changeContainerVolumeUnit: flow(function* (containerId, unitId) {
        try {
          const response = yield api.changeContainerVolumeUnit({
            container_id: containerId,
            volume_unit_id: unitId,
          });
          const updatedContainer =
            response &&
            response.data &&
            Array.isArray(response.data.result) &&
            response.data.result[0];

          if (updatedContainer && updatedContainer.id) {
            self.all.replace(
              self.all.map(c => {
                if (c.id === updatedContainer.id) {
                  return {
                    ...updatedContainer,
                    volume_unit: updatedContainer.volume_unit_id,
                  };
                }
                return c;
              }),
            );
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      addContainer(newContainer) {
        self.all.push({ ...newContainer, volume_unit: newContainer.volume_unit_id });
      },

      removeContainer(removedElements) {
        if (!removedElements?.id && !Array.isArray(removedElements)) {
          return;
        } else if (!Array.isArray(removedElements)) {
          removedElements = [removedElements];
        }

        const updatedList = self.all.filter(
          existingEl => !removedElements.some(removedEl => removedEl.id === existingEl.id),
        );

        self.all.replace(updatedList);
      },

      setContainers(containers) {
        const fetchedContainers = containers.map(container => ({
          ...container,
          volume_unit: container.volume_unit_id,
        }));

        self.all.replace(fetchedContainers);
        self.isLoaded = true;
      },

      generateAutocompleteBeverageTitle(id, units) {
        const container = self.getKegContainerByID(Number(id));
        if (!container) return 'Unknown Container';

        const unit = units[container.volume_unit_id]?.short || '';
        const volume =
          unit.toLowerCase() === 'ml'
            ? Number(container.volume_total).toFixed(0)
            : Number(container.volume_total).toFixed(2);

        return container.nickname ? `${container.nickname} (${volume}${unit})` : container.name;
      },
    };
  });
