import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Box, IconButton, Typography, styled } from '@mui/material';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Warning as WarningIcon,
  WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';
import { useStore } from 'hooks';

import { dateUtilities } from 'utils';

const Item = ({ notification, onItemClick }) => {
  const {
    status_code,
    _alarms_alert_caption,
    _coolers_name,
    _conditions_happened_from,
    id,
    alarm,
  } = notification;

  const { notificationsStore } = useStore();

  const handleClickItem = useCallback(
    id => {
      if (status_code === 0) notificationsStore.markAsRead([id]);

      onItemClick(id);
    },
    [status_code, notificationsStore, onItemClick],
  );

  const IconStatus = useMemo(() => {
    if (status_code === 99) {
      return <Box className="resolved">Resolved</Box>;
    }

    if (status_code === 0) {
      return <WarningIcon />;
    }

    return <WarningAmberIcon />;
  }, [status_code]);

  return (
    <StyledBox onClick={() => handleClickItem(id)}>
      <Box className="first_row">
        {IconStatus}
        <Typography className={clsx('title', { unread: status_code === 0 })}>
          {_alarms_alert_caption + ' Alert'}
        </Typography>
        <Box className={clsx('dot', { hidden: status_code !== 0 })} />
      </Box>
      <Box className="second_row">
        <Typography className={clsx('title', { unread: status_code === 0 })}>
          {_coolers_name} - {alarm?.metadata?.original_sensor_name || alarm?.operand_id}
        </Typography>
        <IconButton>
          <KeyboardArrowRightIcon className="arrow_icon" />
        </IconButton>
      </Box>
      <Box className="date_box">
        <Typography className="subtitle">
          {dateUtilities.formatDateIncludingToday(_conditions_happened_from)}
        </Typography>
      </Box>
    </StyledBox>
  );
};

export default observer(Item);

const StyledBox = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  position: 'relative',
  padding: '16px 0',
  borderTop: '1px solid #313131',

  '.date_box': {
    width: '100%',
    display: 'flex',
    position: 'relative',
    top: '5px',
  },

  '.first_row': {
    display: 'flex',
    alignItems: 'center',

    svg: {
      color: palette.status.red,
      marginRight: '8px',
      height: '16px',
      width: '16px',
    },

    '.dot': {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: palette.primary.main,
      marginLeft: '8px',
    },

    '.resolved': {
      color: palette.status.green,
      border: `1px solid ${palette.status.green}`,
      borderRadius: '36px',
      fontSize: '12px',
      padding: '2px 4px',
      marginRight: '8px',
    },
  },

  '.second_row': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '.title': {
    fontSize: '14px',
    color: palette.whiteEmphasis.medium,

    '&.unread': {
      color: palette.whiteEmphasis.high,
    },
  },
  '.subtitle': {
    fontSize: '12px',
    color: palette.whiteEmphasis.medium,
  },

  '.arrow_icon': {
    color: palette.whiteEmphasis.high,
  },

  '.hidden': {
    display: 'none',
  },
}));
