import { types } from 'mobx-state-tree';

export const Pour = types.model({
  id: types.identifierNumber,
  bru_id: types.maybeNull(types.number),
  archived: types.boolean,
  ts_start: types.maybeNull(types.number),
  poured_at: types.maybeNull(types.string),
  sensor_id: types.maybeNull(types.number),
  bru_temp_c: types.maybeNull(types.number),
  conditions: types.maybeNull(types.array(types.number)),
  gateway_id: types.maybeNull(types.number),
  received_at: types.maybeNull(types.string),
  ts_duration: types.maybeNull(types.string),
  bru_humidity: types.maybeNull(types.number),
  bru_barometric: types.maybeNull(types.number),
  bru_temp_c_raw: types.maybeNull(types.number),
  sensor_ma_zero: types.maybeNull(types.number),
  corrected_using: types.maybeNull(types.number),
  sensor_total_ml: types.maybeNull(types.number),
  corrected_total_ml: types.maybeNull(types.number),
  conditions_set_at: types.maybeNull(types.string),
  sensor_temp_c_stop: types.maybeNull(types.number),
});
