import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { dateUtilities } from 'utils';
import { Schedule } from 'models/types/schedules';
import { Type } from 'models/types/type';
import { getRootStore } from 'models/root';
import { format } from 'date-fns';

import { days } from 'config/days';

export const scheduleModelInitialData = {
  all: [],
};

const dayOrder = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};

export const scheduleModel = types
  .model({
    businessSchedules: types.array(Schedule),
    happySchedules: types.array(Schedule),
    eventSchedules: types.array(Schedule),
    scheduleTypes: types.array(Type),
  })
  .views(self => ({
    get businessHours() {
      try {
        return self.businessSchedules
          .map(schedule => {
            try {
              const [fromDay, fromTime] = ((schedule && schedule.from_token) || '').split(/\s+/);
              const [, toTime] = ((schedule && schedule.to_token) || '').split(/\s+/);

              const from = dateUtilities.convertToken(fromTime);
              const to = dateUtilities.convertToken(toTime);

              return {
                id: schedule.id,
                day: days[fromDay],
                shortcut: fromDay,
                from: from || null,
                to: to || null,
              };
            } catch (err) {
              console.log(err);
              return {};
            }
          })
          .filter(schedule => schedule?.id && schedule?.from && schedule?.to && schedule?.day)
          .sort((a, b) => {
            const scheduleDayA = a?.day;
            const scheduleDayB = b?.day;

            if (!scheduleDayA || !scheduleDayB) {
              return 0;
            }

            if (dayOrder[scheduleDayA] < dayOrder[scheduleDayB]) {
              return -1;
            } else if (dayOrder[scheduleDayA] > dayOrder[scheduleDayB]) {
              return 1;
            } else {
              const scheduleFromTimeA = a?.time;
              const scheduleFromTimeB = b?.time;
              if (
                !scheduleFromTimeA ||
                !scheduleFromTimeB ||
                scheduleFromTimeA === scheduleFromTimeB
              ) {
                return 0;
              } else if (scheduleFromTimeA < scheduleFromTimeB) {
                return -1;
              } else if (scheduleFromTimeA > scheduleFromTimeB) {
                return 1;
              }
            }
            return 0;
          });
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    get happyHours() {
      try {
        return self.happySchedules
          .map(schedule => {
            try {
              const [fromDay, fromTime] = ((schedule && schedule.from_token) || '').split(/\s+/);
              const [, toTime] = ((schedule && schedule.to_token) || '').split(/\s+/);

              const from = dateUtilities.convertToken(fromTime);
              const to = dateUtilities.convertToken(toTime);

              return {
                id: schedule.id,
                day: days[fromDay],
                shortcut: fromDay,
                from: from || null,
                to: to || null,
              };
            } catch (err) {
              console.log(err);
              return {};
            }
          })
          .filter(schedule => schedule?.id && schedule?.from && schedule?.to && schedule?.day)
          .sort((a, b) => {
            const scheduleDayA = a?.day;
            const scheduleDayB = b?.day;

            if (!scheduleDayA || !scheduleDayB) {
              return 0;
            }

            if (dayOrder[scheduleDayA] < dayOrder[scheduleDayB]) {
              return -1;
            } else if (dayOrder[scheduleDayA] > dayOrder[scheduleDayB]) {
              return 1;
            } else {
              const scheduleFromTimeA = a?.from;
              const scheduleFromTimeB = b?.from;
              if (
                !scheduleFromTimeA ||
                !scheduleFromTimeB ||
                scheduleFromTimeA === scheduleFromTimeB
              ) {
                return 0;
              } else if (scheduleFromTimeA < scheduleFromTimeB) {
                return -1;
              } else if (scheduleFromTimeA > scheduleFromTimeB) {
                return 1;
              }
            }
            return 0;
          });
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    get events() {
      try {
        return self.eventSchedules
          .map(schedule => {
            try {
              const [fromDate, fromTime] = ((schedule && schedule.from_token) || '').split(/\s+/);
              const [, toTime] = ((schedule && schedule.to_token) || '').split(/\s+/);

              const from = dateUtilities.convertToken(fromTime);
              const to = dateUtilities.convertToken(toTime);

              let lines = '';
              const linesAll = schedule?.metadata?.lines_all;
              if ((linesAll && linesAll !== 'false') || linesAll === 'true') {
                lines = 'All';
              } else if (schedule?.metadata?.lines) {
                lines = schedule?.metadata?.lines;
              }

              return {
                id: schedule.id,
                identifier: schedule.identifier,
                fromDate: fromDate,
                fromTime,
                date: format(new Date(fromDate), 'd MMM yyyy'),
                lines,
                from: from || null,
                to: to || null,
              };
            } catch (err) {
              console.log(err);
              return {};
            }
          })
          .filter(
            schedule =>
              schedule?.id &&
              schedule?.from &&
              schedule?.to &&
              schedule?.date &&
              schedule?.identifier &&
              schedule?.lines,
          )
          .sort((a, b) => {
            const scheduleDateA = a?.fromDate;
            const scheduleDateB = b?.fromDate;

            if (!scheduleDateA || !scheduleDateB) {
              return 0;
            }

            if (dayOrder[scheduleDateA] < dayOrder[scheduleDateB]) {
              return -1;
            } else if (dayOrder[scheduleDateA] > dayOrder[scheduleDateB]) {
              return 1;
            } else {
              const scheduleFromTimeA = a?.fromTime;
              const scheduleFromTimeB = b?.fromTime;
              if (
                !scheduleFromTimeA ||
                !scheduleFromTimeB ||
                scheduleFromTimeA === scheduleFromTimeB
              ) {
                return 0;
              } else if (scheduleFromTimeA < scheduleFromTimeB) {
                return -1;
              } else if (scheduleFromTimeA > scheduleFromTimeB) {
                return 1;
              }
            }
            return 0;
          });
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    get businessHourType() {
      const typeObject = self.scheduleTypes.find(type => type.short_name === 'Bus Hrs');
      if (typeObject) {
        return typeObject.id;
      }
      return null;
    },
    get happyHourType() {
      const typeObject = self.scheduleTypes.find(type => type.short_name === 'Happy Hrs');
      if (typeObject) {
        return typeObject.id;
      }
      return null;
    },
    get eventType() {
      const typeObject = self.scheduleTypes.find(type => type.short_name === 'Party');
      if (typeObject) {
        return typeObject.id;
      }
      return null;
    },
    getIdentifierByType(type) {
      switch (type) {
        case self.businessHourType:
          return 'BH';
        case self.happyHourType:
          return 'HH';
        case self.eventType:
          return 'Evt';
        default:
          return 'schedule';
      }
    },
  }))
  .actions(self => ({
    //action never used
    fetchScheduleTypes: flow(function* () {
      try {
        const response = yield api.fetchScheduleTypes({
          short_name: ['Bus Hrs', 'Happy Hrs', 'Party'],
        });
        self.scheduleTypes.replace(response.data.result);
        return response.data.result;
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }),
    //action never used
    fetchBusinessHours: flow(function* () {
      try {
        const response = yield api.fetchBusinessHours();
        self.businessSchedules.replace(response.data.result);
        return response.data.result;
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }),
    //action never used
    fetchHappyHours: flow(function* () {
      try {
        const response = yield api.fetchHappyHours();
        self.happySchedules.replace(response.data.result);
        return response.data.result;
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }),
    //action never used
    fetchEvents: flow(function* () {
      try {
        const response = yield api.fetchEvents();
        self.eventSchedules.replace(response.data.result);
        return response.data.result;
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }),
    patchBusinessHours: flow(function* (id, body) {
      try {
        const response = yield api.patchSchedule(body, { id });
        self.businessSchedules.replace(
          self.businessSchedules.map(schedule => {
            if (id === schedule.id) {
              return response.data.row;
            }
            return schedule;
          }),
        );
        return response.data.row;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    patchHappyHours: flow(function* (id, body) {
      try {
        const response = yield api.patchSchedule(body, { id });
        self.happySchedules.replace(
          self.happySchedules.map(schedule => {
            if (id === schedule.id) {
              return response.data.row;
            }
            return schedule;
          }),
        );
        return response.data.row;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    patchEvents: flow(function* (id, body) {
      try {
        const response = yield api.patchSchedule(body, { id });
        self.eventSchedules.replace(
          self.eventSchedules.map(schedule => {
            if (id === schedule.id) {
              return response.data.row;
            }
            return schedule;
          }),
        );
        return response.data.row;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    create: flow(function* (info) {
      try {
        const { establishmentStore } = getRootStore();
        const { type } = info;

        const body = {
          establishment_id: establishmentStore?.establishment?.id,
          priority: 1,
          identifier: self.getIdentifierByType(type),
          recurring: true,
          effective_from: new Date(),
          ...info,
        };

        const response = yield api.createSchedule(body);

        if (type === self.businessHourType) {
          self.businessSchedules.push(response.data.row);
        } else if (type === self.happyHourType) {
          self.happySchedules.push(response.data.row);
        } else if (type === self.eventType) {
          self.eventSchedules.push(response.data.row);
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    delete: flow(function* (id, type) {
      try {
        const response = yield api.deleteSchedule(id);

        if (type === self.businessHourType) {
          self.businessSchedules.replace(
            self.businessSchedules.filter(schedule => schedule.id !== id),
          );
        } else if (type === self.happyHourType) {
          self.happySchedules.replace(self.happySchedules.filter(schedule => schedule.id !== id));
        } else if (type === self.eventType) {
          self.eventSchedules.replace(self.eventSchedules.filter(schedule => schedule.id !== id));
        }

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    setBusinessHours(businessHours) {
      self.businessSchedules = businessHours;
    },

    setHappyHours(happyHours) {
      self.happySchedules = happyHours;
    },

    setScheduleTypes(scheduleTypes) {
      self.scheduleTypes = scheduleTypes;
    },

    setEvents(events) {
      self.eventSchedules = events;
    },
  }));
