import { types } from 'mobx-state-tree';
import { getRootEnv, getRootStore } from 'models/root';

import socketClient from 'services/socketClient';

const TOKEN = 'bar_track_pwa_token';
export const socketsInitialState = {};
export const socketsModel = types.model({}).actions(self => {
  // eslint-disable-next-line no-unused-vars
  let socket;
  return {
    onStorageChange(event) {
      const { userStore } = getRootStore();
      if (event instanceof StorageEvent) {
        if (event.key === TOKEN) {
          if (event.newValue) {
            window.location.reload();
          } else {
            userStore.logout();
          }
        }
      }
    },
    subscribe() {
      const {
        userStore,
        linesStore,
        beveragesStore,
        containersStore,
        establishmentStore,
        itemLinesStore,
        itemsStore,
        skuStore,
        itemEventsStore,
        cleaningsStore,
        topologyManagementStore,
        linesStatisticsStore,
        tapsStore,
        lineTapsStore,
      } = getRootStore();
      if (userStore.isAuthenticated) {
        socketClient.connect(userStore.auth_token);
      }
      window.addEventListener('storage', self.onStorageChange, false);
      const env = getRootEnv();
      if (env && env.socket && env.socket.connection) {
        env.socket.connection.removeAllListeners('notification');
        socket = env.socket.connection.on('notification', message => {
          const { operation, result, by, tableName } = message || {};
          if (userStore.profile && userStore.profile.id !== by) {
            if (
              result &&
              tableName === 'establishments' &&
              establishmentStore.establishment.id === result.id
            ) {
              return establishmentStore.setEstablishment(result);
            }
            switch (operation) {
              case 'copy_beverage': {
                return beveragesStore.handleBeverageCopy(result);
              }
              case 'create_beverages': {
                return beveragesStore.handleCreatedBeverages(result);
              }
              case 'updateGeneric': {
                if (tableName === 'skus') {
                  return skuStore.handleSkuUpdate(result);
                }
                if (tableName === 'beverages') {
                  return beveragesStore.handleBeverageUpdate(result);
                }
                if (tableName === 'containers') {
                  return containersStore.updateContainerById(result);
                }
                break;
              }
              case 'deleteGenericCollection':
              case 'deleteGenericElement': {
                if (tableName === 'skus') {
                  return skuStore.handleSkuDelete(result);
                }
                if (tableName === 'beverages') {
                  return beveragesStore.handleBeverageDelete(result);
                }
                if (tableName === 'containers') {
                  return containersStore.removeContainer(result);
                }
                break;
              }
              case 'createGeneric': {
                if (tableName === 'skus') {
                  return skuStore.handleSkuCreate(result);
                }
                if (tableName === 'containers') {
                  return containersStore.addContainer(result);
                }
                break;
              }
              case 'line_connect_new_item': {
                itemsStore.handleNewItemConnection(result);
                itemLinesStore.handleNewItemConnection(result);
                break;
              }
              case 'line_disconnect_current_item': {
                linesStore.handleItemDisconnection(result);
                itemsStore.handleItemDisconnection(result);
                itemLinesStore.handleItemDisconnection(result);
                break;
              }
              case 'item_to_line_queueindex': {
                itemLinesStore.handleNewItemLine(result);
                itemEventsStore.handleNewItemLine(result);
                break;
              }
              case 'itemline_remove': {
                itemLinesStore.handleItemLineRemove(result);
                itemEventsStore.handleItemLineRemove(result);
                break;
              }
              case 'itemline_reorder': {
                return itemLinesStore.handleItemLineReorder(result);
              }
              case 'line_next_item': {
                itemsStore.handleNextItem(result);
                itemLinesStore.handleNextItem(result);
                itemEventsStore.handleNextItem(result);
                linesStore.handleNextItem(result);
                break;
              }
              case 'swap_items': {
                itemsStore.handleSwapItems(result);
                itemLinesStore.handleSwapItems(result);
                itemEventsStore.handleSwapItems(result);
                break;
              }
              case 'add_item': {
                itemsStore.handleAddItem(result);
                itemEventsStore.handleAddItem(result);
                break;
              }
              case 'add_items': {
                itemsStore.handleAddItem(result._items_added);
                itemEventsStore.handleAddItem(result._item_events);
                break;
              }
              case 'remove_item': {
                itemsStore.handleRemoveItem(result);
                itemEventsStore.handleRemoveItem(result);
                break;
              }
              case 'processPour': {
                linesStore.handleProcessPour(result);
                topologyManagementStore.handleProcessPour(result);
                linesStatisticsStore.handleProcessPour(result);
                itemsStore.handleProcessPour(result);
                break;
              }
              case 'clean_start': {
                linesStore.handleCleanStart(result);
                cleaningsStore.handleCleanStart(result);
                break;
              }
              case 'clean_stop': {
                linesStore.handleCleanStop(result);
                cleaningsStore.handleCleanStop(result);
                break;
              }
              case 'processSensorHeartbeat': {
                linesStore.handleHeartbeat(result);
                topologyManagementStore.handleHeartbeat(result);
                establishmentStore.handleHeartbeat(result);
                break;
              }
              case 'add_sensor_sample': {
                topologyManagementStore.handleAddSensorSample(result);
                break;
              }
              default:
                break;
            }
          } else {
            // Fix for BP-829
            // We have to invoke this and for current app user
            // Manually removing item line duplicates from the front-end, as the server does not update the existing item line,
            // but instead creates a new record which gets populated in the app state and as a result we had multiple records.
            // This issue occurs if during the keg correction one of the queued items was used.
            if (operation === 'line_connect_new_item') {
              const { connected } = result || {};

              connected.forEach(connectedItem => {
                const { _item_line } = connectedItem || {};
                itemLinesStore.removeDuplicateItemLineRecord(_item_line);
              });
            }
          }

          switch (operation) {
            case 'createGeneric': {
              if (tableName === 'lines') {
                return linesStore.updateLine(result);
              }
              if (tableName === 'taps') {
                return tapsStore.updateTap(result);
              }
              break;
            }
            case 'line_connect_new_tap': {
              lineTapsStore.updateLineTap(result?.connected?._line_taps[0]);

              break;
            }
            case 'line_connect_new_sensor': {
              topologyManagementStore.updateLineSensor(result?.connected?._line_sensors[0]);
              break;
            }
            case 'line_connect_new_item': {
              const { _lines = [], connected = [] } = result || {};

              connected.forEach(({ _item_line, _item, _item_events_summary }) => {
                itemLinesStore.updateItemLine(_item_line);

                const itemEventsSummaries = Array.isArray(_item_events_summary)
                  ? _item_events_summary
                  : [];

                itemEventsSummaries.forEach(summary => {
                  itemsStore.updateItem({
                    id: _item.id,
                    summary,
                  });
                });

                itemsStore.updateItem(_item);
              });

              _lines && _lines.length && _lines.forEach(linesStore.updateLine);

              break;
            }
            case 'clear_sensors_lines': {
              const {
                disconnected_items,
                disconnected_sensors,
                disconnected_taps,
                removed_queued_items,
                stopped_cleaning,
              } = result;

              if (disconnected_sensors.count > 0) {
                disconnected_sensors.disconnected._line_sensor.forEach(
                  topologyManagementStore.updateLineSensor,
                );
              }

              if (disconnected_taps.count > 0) {
                disconnected_taps.disconnected._line_taps.forEach(lineTapsStore.updateLineTap);
                disconnected_taps.disconnected._taps.forEach(tapsStore.updateTap);
              }

              if (disconnected_items.count > 0) {
                disconnected_items.disconnected._item_lines.forEach(itemLinesStore.updateItemLine);
                disconnected_items.disconnected._items.forEach(itemsStore.updateItem);
                disconnected_items.disconnected._lines.forEach(linesStore.updateLine);
              }

              if (removed_queued_items.rowCount > 0) {
                removed_queued_items.removed._item_events.forEach(
                  itemEventsStore.updateOrInsertItemEvent,
                );

                const removedItemLinesIds = removed_queued_items.removed._item_lines.map(
                  ({ id }) => id,
                );
                removedItemLinesIds.forEach(itemLinesStore.removeItemLineById);

                removed_queued_items.removed._items.forEach(_item => {
                  const item = itemsStore.all.find(item => item.id === _item.id);

                  itemsStore.updateItem({
                    ...item,
                    ..._item,
                    _queued_count: item._queued_count - 1,
                  });
                });
              }

              if (stopped_cleaning.rowCount > 0) {
                stopped_cleaning.stopped.forEach(cleaningsStore.updateOrInsert);
              }

              break;
            }
            default:
              break;
          }
        });
        socket = env.socket.connection.on('alert', message => {
          const { operation, result } = message || {};
          switch (operation) {
            case 'pourRule': {
              linesStore.handlePourRule(result);
              linesStatisticsStore.handlePourRule(result);
              break;
            }
            default:
              break;
          }
        });

        socket = env.socket.connection.on('self_setup_activation', code => {
          establishmentStore.setActivationCode(code);
        });
      }
    },

    unsubscribe() {
      window.removeEventListener('storage', self.onStorageChange);
    },

    refresh() {
      const token = localStorage.getItem(TOKEN);
      if (token) {
        socketClient.reinitialize(token);
        self.subscribe();
      }
    },
  };
});
