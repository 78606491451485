import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Drawer, useMediaQuery, styled } from '@mui/material';

import { useStore } from 'hooks';
import MenuList from 'components/@Settings/MenuList';

import { MENU_OPENED_WIDTH, MENU_COLLAPSED_WIDTH } from 'config/ui';

const Menu = () => {
  const { ui } = useStore();
  const is640OrSmaller = useMediaQuery('(max-width: 640px)');

  const drawerStateClasses = clsx({
    drawerOpen: ui.isOpenedMenu,
    drawerClose: !ui.isOpenedMenu,
  });

  return (
    <StyledRootDrawer
      variant="permanent"
      headerHeight={ui.headerHeight(is640OrSmaller)}
      className={clsx('drawer', drawerStateClasses)}
      classes={{
        paper: clsx('paper', drawerStateClasses),
      }}
    >
      <MenuList leftMenu />
    </StyledRootDrawer>
  );
};

export default observer(Menu);

const StyledRootDrawer = styled(Drawer, {
  shouldForwardProp: props => props !== 'headerHeight',
})(({ theme, headerHeight }) => ({
  '& .MuiPaper-root': {
    top: `${headerHeight}px !important`,
  },

  '.drawer': {
    width: MENU_OPENED_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  '.drawerOpen': {
    width: MENU_OPENED_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  '.drawerClose': {
    width: MENU_COLLAPSED_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    '& .MuiList-root': {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  '.paper': {
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    height: `calc(100% - ${headerHeight}px)`,
  },
}));
