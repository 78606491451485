import { types, flow } from 'mobx-state-tree';
import { Subscription } from 'models/types';
import { differenceInSeconds } from 'date-fns';
import { getRootStore } from 'models/root';
import uniqBy from 'lodash/uniqBy';

import api from 'services/API';
import { DAY_IN_SECONDS } from 'config/constants';

export const subscriptionsInitialState = {
  isLoaded: false,
  subscriptions: [],
  state: 'done',
  updated: null,
};

export const subscriptionsModel = types
  .model({
    subscriptions: types.array(Subscription),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    isLoaded: types.boolean,
    updated: types.maybeNull(types.Date),
  })
  .views(self => ({
    demoRequestSentRecently(subscriptionType) {
      const root = getRootStore();

      if (
        !root.establishmentStore.establishment.metadata?.demo_request_sent_at?.[subscriptionType]
      ) {
        return false;
      }

      const difference = differenceInSeconds(
        new Date(),
        new Date(
          root.establishmentStore.establishment.metadata.demo_request_sent_at[subscriptionType],
        ),
      );

      return difference < DAY_IN_SECONDS
        ? root.establishmentStore.establishment.metadata.demo_request_sent_at[subscriptionType]
        : false;
    },

    cancellationRequestSentRecently(subscriptionType) {
      const root = getRootStore();

      if (
        !root.establishmentStore.establishment.metadata?.cancellation_request_sent_at?.[
          subscriptionType
        ]
      ) {
        return false;
      }

      const difference = differenceInSeconds(
        new Date(),
        new Date(
          root.establishmentStore.establishment.metadata.cancellation_request_sent_at[
            subscriptionType
          ],
        ),
      );

      return difference < DAY_IN_SECONDS
        ? root.establishmentStore.establishment.metadata.cancellation_request_sent_at[
            subscriptionType
          ]
        : false;
    },

    subscriptionNameByType(type) {
      return self.subscriptions.find(item => item.plan.type === type).plan.name;
    },

    get isDraftActive() {
      return Boolean(
        self.subscriptions.find(
          item =>
            item.plan.type === 'smartDraft' &&
            (item.plan.overridden || item.data?.status === 'active'),
        ),
      );
    },
    get isSmartMonitoringActive() {
      return Boolean(
        self.subscriptions.find(
          item =>
            item.plan.type === 'smartMonitoringCooling' &&
            (item.plan.overridden || item.data?.status === 'active'),
        ),
      );
    },
    get formattedSubscriptions() {
      const activeSubscriptions = self.subscriptions.map(item => {
        const payment_info = item.data?.payment_type
          ? item.data[`${item.data.payment_type}_data`]
          : null;
        const card_info =
          item.data?.payment_type === 'ach'
            ? {
                maskedBankAccountNumber: payment_info.maskedBankAccountNumber.slice(
                  payment_info.maskedBankAccountNumber.length - 4,
                  payment_info.maskedBankAccountNumber.length,
                ),
                maskedBankRoutingNumber: payment_info.maskedBankRoutingNumber.slice(
                  payment_info.maskedBankRoutingNumber.length - 4,
                  payment_info.maskedBankRoutingNumber.length,
                ),
              }
            : item.data?.payment_type === 'card'
            ? {
                maskedCardNumber: payment_info.maskedCardNumber.slice(
                  payment_info.maskedCardNumber.length - 4,
                  payment_info.maskedCardNumber.length,
                ),
              }
            : null;

        const status = item.plan?.overridden ? 'active' : item.data?.status;
        const formattedPrice = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(item.data?.price / 100);

        return {
          ...item,
          data: {
            ...item.data,
            price: formattedPrice,
            status: status,
            payment_info: {
              ...payment_info,
              cardType: payment_info?.cardType || 'ach',
              ...card_info,
            },
          },
        };
      });

      return uniqBy([...activeSubscriptions], 'plan.type');
    },
  }))
  .actions(self => ({
    requestCancellationSubscription: flow(function* ({ subscriptionType }) {
      const root = getRootStore();
      try {
        const response = yield api.requestCancellationSubscription({ subscriptionType });

        root.establishmentStore.setRequestCancelSubscriptionMetadata(
          response.data.result.cancellation_request_sent_at,
        );

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    fetch: flow(function* () {
      try {
        const response = yield api.getSubscriptions();
        self.subscriptions.replace(response.data.rows);
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    requestDemoSubscription: flow(function* ({ subscriptionType }) {
      const root = getRootStore();
      try {
        const response = yield api.requestDemoSubscription({ subscriptionType });

        root.establishmentStore.setRequestDemoSubscriptionMetadata(
          response.data.result.demo_request_sent_at,
        );

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    setSubscriptions(subscriptions) {
      self.subscriptions.replace(subscriptions);
    },
  }));
