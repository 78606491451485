import { types, flow } from 'mobx-state-tree';

import { sortBy, groupBy } from 'lodash';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { DeviceHistory } from 'models/types';
import { Gateway } from 'models/types';
import { numberUtilities, stringUtilities, dateUtilities } from 'utils';

const colors = [
  '#708090',
  '#FF7A00',
  '#800000',
  '#F44336',
  '#0BC207',
  '#FF1493',
  '#FFCE44',
  '#13A5BC',
  '#9467BD',
  '#8C564B',
  '#FFCE44',
  '#13A5BC',
];
const captions = [
  'Cooler',
  'Glycol Pump #4',
  'Glycol Pump #3',
  'Glycol Pump #2',
  'Glycol Pump #1',
  'Glycol Bath',
  'Ambient',
  'Compressor',
  'Tower #1',
  'Tower #2',
  'Humidity',
  'Temperature',
];

const names_map = {
  bru_hum: 'Humidity',
  bru_temp_c: 'Temperature',
  bru_baro: 'Pressure',
};

export const deviceHistoryInitialState = {
  isLoaded: false,
  gateways: [],
  selectedGateways: [],
  selectedGatewayId: null,
  dataset: [],
  disabledDatasets: [],
  period: {
    from: null,
    to: null,
  },
  state: 'done',
  updated: null,
  hiddenDatasetKeys: [],
  activeTab: 'equipment',
};

export const deviceHistoryModel = types
  .model({
    isLoaded: types.boolean,
    gateways: types.array(Gateway),
    selectedGateways: types.array(types.integer),
    selectedGatewayId: types.maybeNull(types.integer),
    dataset: types.array(DeviceHistory),
    disabledDatasets: types.array(types.string),
    period: types.model({
      from: types.maybeNull(types.string),
      to: types.maybeNull(types.string),
    }),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    hiddenDatasetKeys: types.array(types.string),
    activeTab: types.enumeration('activeTab', ['equipment', 'alerts']),
  })
  .views(self => ({
    get items() {
      if (self.dataset.length) {
        const gateway = self.activeGateway;
        const labels = Array.from(self.dataset).map(item => item.bin_from);
        if (gateway?.type_id === 0) {
          const datasetEntryWithBruSummary = self.dataset.find(entry => entry.bru_summary !== null);
          const names = Object.keys(datasetEntryWithBruSummary.bru_summary || {});
          const dataset = names.map((name, index) => {
            const data = self.dataset.map(data => {
              const mean = data.bru_summary?.[name]?.mean;

              if (name === 'bru_temp_c') {
                return numberUtilities.convertTemperature(mean || null);
              } else {
                return mean?.toFixed(1) || null;
              }
            });
            const formattedName = names_map[name];
            const colorIndex = captions.findIndex(caption => formattedName.includes(caption));
            return {
              active: !self.hiddenDatasetKeys.includes(formattedName),
              key: formattedName,
              color: colors[colorIndex],
              values: data,
            };
          });
          return {
            labels: labels,
            names: names,
            dataset: dataset,
          };
        } else {
          const valid_dataset = self.dataset.find(data => data.connected_sensors !== null);
          const names = Array.from(valid_dataset?.connected_sensors?.keys() || []);
          const dataset = names.map((name, index) => {
            const [formattedName] = name.split(' (');
            const colorIndex = captions.findIndex(caption => name.includes(caption));
            let data = [];
            let conditions = [];
            self.dataset.forEach(item => {
              const temperature =
                stringUtilities
                  .makeTemperature(item.connected_sensors?.get(name)?.mean)
                  ?.replace(/(°F|°C)/, '') || null;

              data.push(
                formattedName.key === 'Humidity'
                  ? item.connected_sensors?.get(name)?.mean
                  : temperature,
              );
              conditions.push(item.connected_sensors?.get(name)?.conditions || null);
            });

            return {
              active: !self.hiddenDatasetKeys.includes(formattedName),
              key: formattedName,
              color: colors[colorIndex],
              values: data,
              conditions: conditions,
            };
          });

          return {
            labels: labels,
            names: names,
            dataset: dataset,
          };
        }
      }

      return {
        labels: [],
        names: [],
        dataset: [],
      };
    },

    get disabledItems() {
      return self.disabledDatasets;
    },

    get activeGateway() {
      return self.gateways.find(({ id }) => id === self.selectedGatewayId);
    },

    get lines() {
      const root = getRootStore();
      return root.linesStatisticsStore?.sortedLinesById(self.activeGateway?.cooler_id);
    },

    get sortedGateways() {
      const root = getRootStore();

      const grouped = groupBy(
        self.gateways.filter(e => Boolean(e.cooler_id)),
        'cooler_id',
      );

      let result = [];
      Object.values(grouped).forEach(items => {
        if (items.length > 1) {
          const filteredTraditional = items.filter(item => item.type_id === 0);
          result.push(...filteredTraditional);

          const filteredNonTraditional = items.filter(item => item.type_id !== 0);
          result.push(...filteredNonTraditional);
        } else {
          result.push(...items);
        }
      });

      result = result.map(gateway => {
        const conditions = root.notificationsStore.getActiveConditionsByGatewayId(gateway.id);
        return {
          ...gateway,
          conditionCount: conditions.length,
        };
      });

      return sortBy(result, '_coolers_name').filter(Boolean);
    },

    get bins() {
      // Used for API and calculation number of X axis chart labels
      return 200;
    },
  }))
  .actions(self => ({
    fetchIdentifiers: flow(function* () {
      try {
        self.state = 'pending';
        const response = yield api.getGetaways();

        if (response?.data?.result) {
          self.gateways.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),
    fetchDataset: flow(function* () {
      try {
        if (!self.activeGateway?.cooler_id) return;

        self.state = 'pending';

        let { from, to } = self.period;

        if (!from || !to) {
          ({ from, to } = dateUtilities.getDatesByPeriod('1'));
        }

        const body = {
          from_ts: from,
          to_ts: to,
          bins: self.bins,
        };

        const gateway = self.activeGateway;
        let response = null;
        if (gateway.type_id === 0) {
          const params = {
            id: gateway.cooler_id,
          };
          response = yield api.getCoolerBruSummary(body, params);
        } else {
          const params = {
            identifier: gateway.identifier,
          };
          response = yield api.getDeviceHistory(body, params);
        }

        if (response?.data?.result) {
          self.dataset.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setPeriod(period) {
      self.period.from = period.from;
      self.period.to = period.to;
    },

    updateDisabledDataset(key) {
      if (self.disabledDatasets.includes(key)) {
        self.disabledDatasets.replace(self.disabledDatasets.filter(item => item !== key));
      } else {
        self.disabledDatasets.push(key);
      }
    },

    setSelectedGatewayId(id) {
      self.selectedGatewayId = id;
      self.fetchDataset();
    },

    setDeviceHistory(rows) {
      self.gateways.replace(rows);
    },

    setActiveTab(tab) {
      if (['equipment', 'alerts'].includes(tab)) self.activeTab = tab;
    },

    changeVisibility(key) {
      if (self.hiddenDatasetKeys.includes(key)) {
        self.hiddenDatasetKeys = self.hiddenDatasetKeys.filter(e => e !== key);
      } else {
        self.hiddenDatasetKeys = [...self.hiddenDatasetKeys, key];
      }
    },
  }));
