import React, { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';
import { Box } from '@mui/material';

const SnackbarCustom = forwardRef((props, ref) => {
  const { message, icon = null, overrideStyle = null, ...other } = props;

  const customStyle = overrideStyle || {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    gap: '8px',
    backgroundColor: '#2f2f2f',
    borderRadius: '4px',
  };

  return (
    <SnackbarContent ref={ref} {...other}>
      <Box sx={customStyle}>
        {icon}
        {message}
      </Box>
    </SnackbarContent>
  );
});

export default SnackbarCustom;
