import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { LineTap } from './types';
import { getRootStore } from 'models/root';

export const lineTapsInitialState = {
  all: [],
  isLoaded: false,
};

const LineTapWithViews = LineTap.views(self => ({
  get tap() {
    const root = getRootStore();
    return root.tapsStore.taps.find(el => el.id === self.tap_id);
  },
}));

export const lineTapsModel = types
  .model({
    all: types.array(LineTapWithViews),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get lineTaps() {
      return self.all;
    },
  }))
  .actions(self => {
    return {
      fetchLineTaps: flow(function* () {
        try {
          const response = yield api.getLineTaps();
          if (response && response.data && response.data.result) {
            self.all.replace(response.data.result);
          }
          self.isLoaded = true;
        } catch (err) {
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      setLineTaps(lineTaps) {
        const filtered = lineTaps.filter(({ connected_to }) => !connected_to);

        self.all.replace(filtered);
        self.isLoaded = true;
      },

      updateLineTap(_line_tap) {
        if (!_line_tap?.connected_to) {
          const index = self.all.findIndex(line_tap => line_tap?.id === _line_tap.id);

          if (index >= 0) {
            self.all.replace(
              self.all.map(lineSensor => {
                if (lineSensor.id === _line_tap.id) {
                  return { ...lineSensor, ..._line_tap };
                }
                return lineSensor;
              }),
            );
          } else {
            self.all.replace([...self.all, _line_tap]);
          }
        } else {
          self.all.replace(self.all.filter(lineSensor => lineSensor.id !== _line_tap.id));
        }
      },
    };
  });
