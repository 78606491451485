import { types } from 'mobx-state-tree';

import { Bar } from 'models/types';

export const barsInitialState = {
  isLoaded: false,
  all: [],
  state: 'done',
  updated: null,
};

export const barsModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(Bar),
  })
  .views(self => ({
    get availableBars() {
      return self.all.slice();
    },

    get availableBarIds() {
      return self.all.map(item => item.id);
    },

    getBarById(id) {
      return self.all.find(bar => bar.id === id);
    },

    get availableBarsIds() {
      return self.all.slice().map(e => e.id);
    },

    get defaultBarsIds() {
      return self.availableBars.length === 1 ? [self.availableBars[0]?.id] : [];
    },

    get isSingleBar() {
      return self.availableBars.length === 1;
    },
  }))
  .actions(self => ({
    setBars(bars) {
      self.all.replace(bars);
      self.isLoaded = true;
    },
  }));
