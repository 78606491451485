import { types } from 'mobx-state-tree';

export const Subscription = types.model({
  // id: types.integer,
  data: types.maybeNull(
    types.model({
      price: types.maybeNull(types.number),
      status: types.maybeNull(types.enumeration('status', ['expired', 'active', 'withIssues'])),
      billing_period_type: types.maybeNull(
        types.enumeration('billing_period_type', ['monthly', 'annual']),
      ),
      billing_address_data: types.maybeNull(
        types.model({
          billingZip: types.maybeNull(types.union(types.number, types.string)),
          billingCity: types.maybeNull(types.string),
          billingState: types.maybeNull(types.string),
          billingAddress: types.maybeNull(types.string),
          billingCountry: types.maybeNull(types.string),
          billingAddress2: types.maybeNull(types.string),
        }),
      ),
      payment_type: types.maybeNull(types.enumeration('payment_type', ['card', 'ach'])),
      card_data: types.maybeNull(
        types.model({
          cardType: types.maybeNull(types.string),
          firstName: types.maybeNull(types.string),
          lastName: types.maybeNull(types.string),
          expirationYear: types.maybeNull(types.number),
          expirationMonth: types.maybeNull(types.number),
          maskedCardNumber: types.maybeNull(types.string),
        }),
      ),
      ach_data: types.maybeNull(
        types.model({
          bankName: types.maybeNull(types.string),
          firstName: types.maybeNull(types.string),
          lastName: types.maybeNull(types.string),
          maskedBankAccountNumber: types.maybeNull(types.string),
          maskedBankRoutingNumber: types.maybeNull(types.string),
        }),
      ),
      current_period_started_at: types.maybeNull(types.string),
      current_period_ends_at: types.maybeNull(types.string),
      expires_at: types.maybeNull(types.string),
      update_payment_method_url: types.maybeNull(types.string),
    }),
  ),
  plan: types.maybeNull(
    types.model({
      type: types.maybeNull(types.enumeration('type', ['smartMonitoringCooling', 'smartDraft'])),
      name: types.maybeNull(types.string),
      overridden: types.boolean,
      subscription_ever_existed: types.boolean,
    }),
  ),
});
