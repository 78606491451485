import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { getRootStore } from 'models/root';

export const kegQueueInitialState = {};

export const kegQueueModel = types.model({}).actions(self => {
  return {
    reorderQueuedItems: flow(function* (body) {
      const root = getRootStore();
      try {
        const response = yield api.reorder(body);

        if (response?.data?.result?.length) {
          const updatedIds = response.data.result.map(e => e.id);
          const oldItems = root.itemLinesStore.itemLines.filter(
            itemLine => !updatedIds.includes(itemLine.id),
          );
          root.itemLinesStore.updateItemLines([...oldItems, ...response.data.result]);
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    removeItemFromTheQueue: flow(function* (item_line_id) {
      const root = getRootStore();
      try {
        const response = yield api.removeItemFromTheQueue({ item_line_id });
        const [removed] = response?.data?.result?.removed || [];
        if (removed) {
          const updatedItemLines = root.itemLinesStore.itemLines.filter(
            itemLine => itemLine.id !== item_line_id,
          );
          root.itemLinesStore.updateItemLines(updatedItemLines);
          yield root.itemsStore.queueInventoryReplacement(removed._item_line.item_id);
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    addNewItemToTheBack: flow(function* (line_id, item_id) {
      const root = getRootStore();
      try {
        const response = yield api.addNewItemToTheBack({ line_id, item_id });
        const [added] = response?.data?.result?.added || [];
        if (added) {
          root.itemLinesStore.updateItemLines([...root.itemLinesStore.itemLines, added._item_line]);
          const item = root.itemsStore.all.find(item => item.id === added._item.id);

          root.itemsStore.updateItem({
            ...item,
            ...added._item,
            _queued_count: item._queued_count + 1,
          });
          root.itemsStore.updateItem(response?.data?.result._item);
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
  };
});
