import { types, flow } from 'mobx-state-tree';
import { groupBy } from 'lodash';

import api from 'services/API';
import { getRootStore } from 'models/root';

import { Alarm } from 'models/types';
import { numberUtilities, stringUtilities } from 'utils';

export const alarmsInitialState = {
  all: [],
  state: 'done',
  updated: null,
};

export const alarmsModel = types
  .model({
    all: types.array(Alarm),
  })
  .views(self => ({
    get availableAlarms() {
      return self.all.slice();
    },

    getAlarmById(id) {
      return self.all.find(bar => bar.id === id);
    },

    get subscribedEquipment() {
      const root = getRootStore();
      const roleUserId = root.userStore.profile.currentEstablishmentRole._roleuser_id;

      const alarms = self.all.filter(({ role_users_ids }) => role_users_ids?.includes(roleUserId));

      const sensorIds = alarms.map(alarm => alarm.operand_id);
      const sensors = root.topologyManagementStore.temperatureSensors.filter(s =>
        sensorIds.includes(s.id),
      );
      const groupedSensors = groupBy(sensors, '_gateways_id');
      const gatewaysIds = Object.keys(groupedSensors).map(Number);

      const gateways = root.topologyManagementStore.gateways.filter(g =>
        gatewaysIds.includes(g.id),
      );

      return gateways.map(gateway => {
        const currentAlarms = alarms.filter(a =>
          groupedSensors[gateway.id].map(s => s.id).includes(a.operand_id),
        );
        const currentGatewaysAlarms = self.getAlarmsByGatewayId(gateway.id);

        let extended_alarms = [];
        let alarmsIds = [];
        let mutedIds = [];

        if (currentGatewaysAlarms.length) {
          const grouped = groupBy(currentGatewaysAlarms, 'operand_id');

          extended_alarms = Object.keys(grouped).map(key => {
            const low = grouped[key].find(e => e.rule.includes('<'));
            const high = grouped[key].find(e => e.rule.includes('>'));
            const name = grouped[key].map(e => e.metadata.original_sensor_name).filter(e => e)[0];
            const sensor_name = `Sensor #${grouped[key][0].operand_id}`;

            const min = numberUtilities.celsiusWrapper(
              stringUtilities.sliceNumber(low?.rule, '<', ')'),
              true,
            );
            const max = numberUtilities.celsiusWrapper(
              stringUtilities.sliceNumber(high?.rule, '>', ')'),
              true,
            );
            const default_min = numberUtilities.celsiusWrapper(
              stringUtilities.sliceNumber(low?.default_rule, '<', ')'),
              true,
            );
            const default_max = numberUtilities.celsiusWrapper(
              stringUtilities.sliceNumber(high?.default_rule, '>', ')'),
              true,
            );

            alarmsIds.push(low?.id, high?.id);

            if (low?.role_users_ids_muted?.length) {
              mutedIds.push(...low?.role_users_ids_muted);
            }

            if (high?.role_users_ids_muted?.length) {
              mutedIds.push(...high?.role_users_ids_muted);
            }

            return {
              low,
              high,
              name,
              sensor_name,
              min,
              max,
              default_min,
              default_max,
            };
          });
        }

        return {
          ...gateway,
          alarmsIds,
          muted: currentAlarms.some(a => a.role_users_ids_muted?.includes(roleUserId)),
          alarms: extended_alarms,
          recipients: self.getRecipientsByGatewayId(gateway.id),
        };
      });
    },

    get gatewaysWithAlarmsInSelectedCooler() {
      const root = getRootStore();
      return self.subscribedEquipment
        .filter(gw => gw.cooler_id === root.coolersStore.selectedCooler?.id)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  }))
  .actions(self => ({
    setAlarms(alarms) {
      const updatedIds = alarms.map(({ id }) => id);

      self.all.replace([...self.all.filter(({ id }) => !updatedIds.includes(id)), ...alarms]);
    },

    getAlarmsByGatewayId(gatewayId) {
      const root = getRootStore();
      const roleUserId = root.userStore.profile.currentEstablishmentRole._roleuser_id;

      const alarms = self.all.filter(({ role_users_ids }) => role_users_ids?.includes(roleUserId));

      const sensorIds = alarms.map(alarm => alarm.operand_id);
      const sensors = root.topologyManagementStore.temperatureSensors.filter(s =>
        sensorIds.includes(s.id),
      );

      const currentGatewaySensorsIds = sensors
        .filter(s => s._gateways_id === gatewayId)
        .map(s => s.id);

      return alarms.filter(g => currentGatewaySensorsIds.includes(g.operand_id));
    },

    getRecipientsByGatewayId(gatewayId) {
      const validAlarms = self.getAlarmsByGatewayId(gatewayId);

      return [...new Set(validAlarms.flatMap(({ role_users_ids }) => role_users_ids))];
    },

    muteEquipment: flow(function* (gatewayId) {
      try {
        const root = getRootStore();
        const roleUserId = root.userStore.profile.currentEstablishmentRole._roleuser_id;
        const alarms = self.getAlarmsByGatewayId(gatewayId);
        const alarmsIds = alarms.map(e => e.id);
        const mutedIds = [
          ...new Set(
            alarms
              .filter(e => Array.isArray(e.role_users_ids_muted) && e.role_users_ids_muted.length)
              .flatMap(({ role_users_ids_muted }) => role_users_ids_muted),
          ),
        ];

        const updatedMutedIds = mutedIds.includes(roleUserId)
          ? mutedIds.filter(id => id !== roleUserId)
          : [...mutedIds, roleUserId];

        const response = yield api.patchAlerts(
          { role_users_ids_muted: updatedMutedIds },
          { id: alarmsIds, rows: alarmsIds.length },
        );

        self.setAlarms(response.data.result);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    subscribeToAlerts: flow(function* (body, params) {
      try {
        const response = yield api.subscribeToAlerts(body, params);
        self.setAlarms(response.data.result);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    unsubscribeFromAlerts: flow(function* (body, params) {
      try {
        const response = yield api.unsubscribeFromAlerts(body, params);

        self.setAlarms(response.data.result);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    patchAlert: flow(function* (body, params) {
      try {
        const response = yield api.patchAlerts(body, params);

        self.setAlarms(response.data.result);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),
  }));
