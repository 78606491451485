import { types, flow } from 'mobx-state-tree';
import { ClientJS } from 'clientjs';

import api from 'services/API';
import { Notification, Condition, UserDevice, Alarm } from 'models/types';
import { getRootStore } from 'models/root';
import orderBy from 'lodash/orderBy';

import { stringUtilities, numberUtilities } from 'utils';

const client = new ClientJS();

const NotificationWithView = Notification.views(self => ({
  get condition() {
    const root = getRootStore();

    return root.notificationsStore.conditions.find(condition => condition.id === self.condition_id);
  },

  get conditionAlarmId() {
    return self.condition?.alarm_id;
  },

  get alarm() {
    const root = getRootStore();

    return root.notificationsStore.alarms.find(({ id }) => id === self._conditions_alarm_id);
  },

  get alarmType() {
    return self._alarms_alert_caption.includes('High') ? 'high' : 'low';
  },

  get sensorAlarms() {
    const root = getRootStore();

    return root.notificationsStore.alarms.filter(
      alarm => alarm.operand_id === self.alarm?.operand_id,
    );
  },

  get criteriaValueRaw() {
    return self._conditions_criteria
      ? stringUtilities.ruleToValue(self._conditions_criteria)
      : null;
  },

  get criteriaValue() {
    return Number.isNaN(+self.criteriaValueRaw)
      ? null
      : numberUtilities.celsiusWrapper(self.criteriaValueRaw, { signsAmount: 1 });
  },

  get lowAlarm() {
    return self.sensorAlarms.find(a => a?.rule.includes('<'));
  },

  get highAlarm() {
    return self.sensorAlarms.find(a => a?.rule.includes('>'));
  },

  get minRaw() {
    return self.lowAlarm?.rule ? stringUtilities.ruleToValue(self.lowAlarm?.rule) : null;
  },

  get min() {
    return Number.isNaN(+self.minRaw)
      ? null
      : numberUtilities.celsiusWrapper(self.minRaw, { signsAmount: 1 });
  },

  get maxRaw() {
    return self.highAlarm?.rule ? stringUtilities.ruleToValue(self.highAlarm?.rule) : null;
  },

  get max() {
    return Number.isNaN(+self.maxRaw)
      ? null
      : numberUtilities.celsiusWrapper(self.maxRaw, { signsAmount: 1 });
  },

  get defaultMin() {
    const value = stringUtilities.ruleToValue(self.lowAlarm?.default_rule);

    return value ? numberUtilities.celsiusWrapper(value, { signsAmount: 1 }) : null;
  },
  get defaultMax() {
    const value = stringUtilities.ruleToValue(self.highAlarm?.default_rule);

    return value ? numberUtilities.celsiusWrapper(value, { signsAmount: 1 }) : null;
  },
}));

export const notificationInitialState = {
  isLoaded: false,
  all: [],
  alarms: [],
  conditions: [],
  selectedNotificationId: null,
  devices: [],
  deviceFilters: {
    sortedBy: 'device_name',
    orderBy: 'desc',
  },
  searchString: '',
  state: 'done',
  statusCode: null,
};

export const notificationModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(NotificationWithView),
    alarms: types.array(Alarm),
    devices: types.array(UserDevice),
    deviceFilters: types.maybeNull(
      types.model({
        sortedBy: types.enumeration('sortedBy', ['device_name', 'type', 'created_at']),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
      }),
    ),
    conditions: types.array(Condition),
    selectedNotificationId: types.maybeNull(types.number),
    statusCode: types.maybeNull(types.number),
  })
  .views(self => ({
    get filtered() {
      const notifications =
        self.statusCode === null
          ? self.all.slice()
          : self.all.filter(notification => notification.status_code === self.statusCode);

      const unresolved = notifications
        .filter(notification => notification.status_code !== 99)
        .sort(
          (a, b) =>
            Date.parse(b._conditions_happened_from) - Date.parse(a._conditions_happened_from),
        );
      const resolved = notifications
        .filter(notification => notification.status_code === 99)
        .sort(
          (a, b) =>
            Date.parse(b._conditions_happened_from) - Date.parse(a._conditions_happened_from),
        );

      return [...unresolved, ...resolved];
    },

    get deviceItems() {
      const root = getRootStore();

      let filtered = self.devices.filter(device => device.user_id === root.userStore.profile.id);
      if (self.searchString) {
        const searchArray = self.searchString?.toLowerCase().split(' ');

        filtered = self.devices.filter(item =>
          searchArray.every(element => item.device_name.includes(element)),
        );
      }

      const result = orderBy(filtered, [self.deviceFilters.sortedBy], [self.deviceFilters.orderBy]);
      return result;
    },

    get unread() {
      return self.all
        .filter(n => n.status_code === 0)
        .sort(
          (a, b) =>
            Date.parse(b._conditions_happened_from) - Date.parse(a._conditions_happened_from),
        );
    },

    get unreadCount() {
      return self.unread.length;
    },

    get selectedNotification() {
      return self.all.find(notification => notification.id === self.selectedNotificationId);
    },

    get fingerprint() {
      return client.getFingerprint();
    },

    get isDeviceExist() {
      return self.devices.find(device => device.device_fingerprint === self.fingerprint);
    },

    getActiveConditionsByGatewayId(gateway_id) {
      return self.conditions.filter(
        item => item.context.gateway_id === gateway_id && !item._closed,
      );
    },
  }))
  .actions(self => ({
    fetchDevices: flow(function* () {
      try {
        self.state = 'pending';
        const response = yield api.getUserDevices();

        if (response?.data?.result) {
          self.devices.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),
    patchUserDevices: flow(function* (id, data) {
      try {
        self.state = 'pending';
        const response = yield api.patchUserDevices(id, data);

        if (response?.data) {
          self.devices.replace([
            ...self.devices.filter(e => e.id !== response.data.row.id),
            response.data.row,
          ]);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    registerUserDevice: flow(function* ({ device_name, subscription, notifications_muted }) {
      try {
        self.state = 'pending';

        const response = yield api.registerUserDevice({
          device_name,
          subscription,
          device_fingerprint: self.fingerprint,
          notifications_muted,
        });

        if (response?.data) {
          self.devices.replace([...self.devices, response.data.result[0]]);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    removeDevice: flow(function* (item) {
      try {
        self.state = 'pending';
        const response = yield api.deleteUserDevice(item.id);

        if (response?.data) {
          self.devices.replace([...self.devices.filter(e => e.id !== response.data.row.id)]);
          if (localStorage.getItem('bar_track_pwa_device_identifier') === item.identifier) {
            localStorage.removeItem('bar_track_pwa_device_identifier');
          }
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.row;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),
    markAsRead: flow(function* (ids) {
      try {
        const response = yield api.patchNotification(
          { status_code: 1 },
          { id: ids, rows: ids.length },
        );
        const updatedNotifications = response.data?.result;
        const updatedIds = updatedNotifications.map(({ id }) => id);

        self.all.replace([
          ...self.all.filter(e => !updatedIds.includes(e.id)),
          ...updatedNotifications,
        ]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    testDeviceNotification: flow(function* (device_id) {
      try {
        yield api.testUserDeviceNotification(device_id);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    getConditionById: flow(function* (id) {
      try {
        const response = yield api.getConditionById(id);
        return response.data.result;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setUserDevices(user_devices) {
      self.devices.replace(user_devices);
    },

    setConditions(conditions) {
      self.conditions.replace(
        conditions.map(condition => ({
          ...condition,
          criteria_value: stringUtilities.ruleToValue(condition.criteria),
        })),
      );
    },

    setNotifications(notifications) {
      self.all.replace(notifications);
    },

    setSearchString(value) {
      self.searchString = value;
    },

    setDeviceFilters(filters) {
      if (filters === null) {
        self.deviceFilters = {
          sortedBy: 'timestamp',
          orderBy: 'desc',
        };
      } else {
        self.deviceFilters = {
          sortedBy: filters.sortedBy || self.deviceFilters.sortedBy,
          orderBy: filters.orderBy || self.deviceFilters.orderBy,
        };
      }
    },

    setNotificationId(id = null) {
      self.selectedNotificationId = Number(id);
    },

    setAlarms(rows) {
      self.alarms.replace(rows);
    },

    setStatusCode(statusCode) {
      self.statusCode = statusCode;
    },
  }));
