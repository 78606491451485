import { types, flow } from 'mobx-state-tree';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { areIntervalsOverlapping } from 'date-fns';

import { getRootStore } from 'models/root';
import api from 'services/API';
import { stringUtilities, dateUtilities } from 'utils';
import { sortByAgeOptions } from 'config/skuFilters';
import { POSSKU } from 'models/types/pos-sku';

const getCategories = (array, field = 'category') =>
  uniqBy(array, field)
    .map(sku => sku[field])
    .filter(Boolean)
    .slice();

export const skuMappingStoreInitialState = {
  all: [],
  isLoaded: false,
  filters: {
    categories: {
      matched: [],
      unmatched: [],
      ignored: [],
    },
    subCategories: {
      matched: [],
      unmatched: [],
      ignored: [],
    },
    priceRange: {
      from: 0,
      to: null,
    },
    dateRange: {
      period: 'all',
      from: null,
      to: null,
    },
    numberOfSales: null,
    ignoredBy: [],
    ignoredDatePeriod: '',
    sortedBy: 'age',
    orderBy: 'desc',
  },
  searchString: '',
};

export const skuMappingStore = types
  .model({
    allActive: types.array(POSSKU),
    allIgnored: types.array(POSSKU),
    isLoaded: types.boolean,
    filters: types.model({
      categories: types.model({
        matched: types.array(types.string),
        unmatched: types.array(types.string),
        ignored: types.array(types.string),
      }),
      subCategories: types.model({
        matched: types.array(types.string),
        unmatched: types.array(types.string),
        ignored: types.array(types.string),
      }),
      priceRange: types.model({
        from: types.number,
        to: types.maybeNull(types.number),
      }),
      dateRange: types.model({
        period: types.string,
        from: types.maybeNull(types.string),
        to: types.maybeNull(types.string),
      }),
      numberOfSales: types.maybeNull(types.number),
      ignoredBy: types.array(types.integer),
      ignoredDatePeriod: types.string,
      sortedBy: types.enumeration('sortedBy', [
        'age',
        'item',
        'category',
        'latest_price',
        'beverage',
        'serving_size',
      ]),
      orderBy: types.enumeration('orderBy', ['asc', 'desc']),
    }),
    searchString: types.string,
  })
  .views(self => ({
    get allUnmatchedPOS() {
      let filtered = self.allActive;
      if (self.searchString) {
        const searchArray = self.searchString.toLowerCase().split(' ');
        filtered = self.allActive.filter(pos => {
          return searchArray.every(
            element =>
              pos?.item?.toLowerCase().includes(element) ||
              pos?.category?.toLowerCase().includes(element) ||
              pos?.subcategory?.toLowerCase().includes(element),
          );
        });
      }

      return orderBy(
        filtered.filter(sku => sku.sku_id === null),
        ['earliest_sale_at', 'category', 'subcategory'],
        ['asc', 'asc', 'asc'],
      );
    },

    get unmatchedPOS() {
      return self.filteredItems(self.allUnmatchedPOS, 'unmatched');
    },

    get allUnmatchedBeverages() {
      const root = getRootStore();

      const beverages = self.showHiddenBeverages
        ? root.beveragesStore.allExcludingPrototypes
        : root.beveragesStore.sortedBeverages;

      return orderBy(beverages, ['_producers_name', '_name'], ['asc', 'asc']);
    },

    get unmatchedBeverages() {
      return self.allUnmatchedBeverages;
    },

    get allMatched() {
      const { skuStore } = getRootStore();
      const matched = [];

      let filtered = self.filteredItems(
        self.allActive.filter(sku => sku.sku_id),
        'matched',
      );

      const groupedBySKU = groupBy(filtered, 'sku_id');

      const sortedByList =
        self.filters.sortedBy === 'category'
          ? ['category', 'subcategory']
          : [self.filters.sortedBy];
      const orderByList = Array(sortedByList.length).fill(self.filters.orderBy);

      Object.keys(groupedBySKU).forEach(sku_id => {
        const sku = skuStore.getSKUById(Number(sku_id));
        if (sku && !self.showHiddenBeverages) {
          matched.push({
            id: new Date().getTime(),
            pos: orderBy(groupedBySKU[sku_id], sortedByList, orderByList),
            beverage: { ...sku.beverage, _containers_name: sku._containers_name },
          });
        }
      });

      return matched;
    },

    get matched() {
      return self.allMatched.filter(sku => {
        const isMatchingBartrackItems = stringUtilities.matchSome(self.searchString, [
          sku.beverage._producers_name,
          sku.beverage._name,
        ]);

        const isMatchingPOSItems = sku.pos.some(posItem =>
          stringUtilities.matchSome(self.searchString, [
            posItem.item,
            posItem.category,
            posItem.subcategory,
          ]),
        );

        return isMatchingBartrackItems || isMatchingPOSItems;
      });
    },

    get matchedPOS() {
      return self.sortNestedPOS(self.matched);
    },

    get ignored() {
      return self.allIgnored.slice().sort((a, b) => {
        if (a.item < b.item) {
          return -1;
        } else if (a.item > b.item) {
          return 1;
        } else {
          if (a.category < b.category) {
            return -1;
          } else if (a.category > b.category) {
            return 1;
          } else {
            if (a.subcategory < b.subcategory) {
              return -1;
            } else if (a.subcategory > b.subcategory) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });
    },

    get ignoredPOS() {
      return self.filteredItems(self.allIgnored, 'ignored');
    },

    getItemsByType(type) {
      switch (type) {
        case 'matched':
          return self.matchedPOS;
        case 'unmatched':
          return self.unmatchedPOS;
        case 'ignored':
          return self.ignoredPOS;
        default:
          return [];
      }
    },

    get isFiltersChanged() {
      const { priceRange, dateRange, numberOfSales, sortedBy, orderBy } =
        skuMappingStoreInitialState.filters;

      const commonConditions = Boolean(
        priceRange.from !== self.filters.priceRange.from ||
          priceRange.to !== self.filters.priceRange.to ||
          dateRange.period !== self.filters.dateRange.period ||
          numberOfSales !== self.filters.numberOfSales ||
          sortedBy !== self.filters.sortedBy ||
          orderBy !== self.filters.orderBy,
      );

      const typeConditions = type =>
        Boolean(
          self.allCategories[type].length !== self.filters.categories[type].length ||
            self.allSubCategories[type].length !== self.filters.subCategories[type].length,
        );

      return {
        matched: typeConditions('matched') || commonConditions,
        unmatched: typeConditions('unmatched') || commonConditions,
        ignored: typeConditions('ignored') || commonConditions,
      };
    },

    get allCategories() {
      return {
        matched: getCategories(self.items.matched),
        unmatched: getCategories(self.items.unmatched),
        ignored: getCategories(self.items.ignored),
      };
    },

    get categories() {
      return {
        matched: getCategories(self.filteredWithoutCategory(self.items.matched)),
        unmatched: getCategories(self.filteredWithoutCategory(self.items.unmatched)),
        ignored: getCategories(self.filteredWithoutCategory(self.items.ignored)),
      };
    },

    get allSubCategories() {
      return {
        matched: getCategories(self.items.matched, 'subcategory'),
        unmatched: getCategories(self.items.unmatched, 'subcategory'),
        ignored: getCategories(self.items.ignored, 'subcategory'),
      };
    },

    get subCategories() {
      return {
        matched: getCategories(self.filteredWithoutCategory(self.items.matched), 'subcategory'),
        unmatched: getCategories(self.filteredWithoutCategory(self.items.unmatched), 'subcategory'),
        ignored: getCategories(self.filteredWithoutCategory(self.items.ignored), 'subcategory'),
      };
    },

    get items() {
      return {
        matched: self.allActive.filter(sku => sku.sku_id),
        unmatched: self.allActive.filter(sku => sku.sku_id === null),
        ignored: self.allIgnored,
      };
    },

    get ignoredBy() {
      return self.allIgnored.map(sku => sku.ignore_by).filter(Boolean);
    },

    filteredBySearch(skus) {
      if (!self.searchString) {
        return skus;
      }
      const searchArray = self.searchString.toLowerCase().split(' ');

      return skus.filter(pos => {
        return searchArray.every(
          element =>
            pos?.item?.toLowerCase().includes(element) ||
            pos?.category?.toLowerCase().includes(element) ||
            pos?.subcategory?.toLowerCase().includes(element),
        );
      });
    },
    filteredWithoutCategory(items) {
      let filtered = items;
      if (self.filters.priceRange.from > 0) {
        filtered = filtered.filter(item => item.latest_price >= self.filters.priceRange.from);
      }

      if (self.filters.priceRange.to !== null) {
        filtered = filtered.filter(item => item.latest_price <= self.filters.priceRange.to);
      }

      if (self.filters.dateRange.period !== 'all') {
        const { from, to } = self.filters.dateRange;

        const { to: toYesterDay } = dateUtilities.getDatesByPeriod('yesterday');
        const fromParsed = Date.parse(from);
        const toParsed = to ? Date.parse(to) : Date.parse(toYesterDay);

        filtered = filtered.filter(({ earliest_sale_at, latest_sale_at }) => {
          if (earliest_sale_at && latest_sale_at) {
            return areIntervalsOverlapping(
              { start: Date.parse(earliest_sale_at), end: Date.parse(latest_sale_at) },
              { start: fromParsed, end: toParsed },
              { inclusive: true },
            );
          }

          return (
            Date.parse(earliest_sale_at) >= fromParsed && Date.parse(earliest_sale_at) <= toParsed
          );
        });
      }

      if (self.filters.numberOfSales) {
        filtered = filtered.filter(item => item.total_sales >= self.filters.numberOfSales);
      }

      if (self.filters.ignoredBy.length > 0) {
        filtered = filtered.filter(item => self.filters.ignoredBy.includes(item.ignore_by));
      }

      if (self.filters.ignoredDatePeriod) {
        const { from, to } = dateUtilities.getDatesByPeriod(self.filters.ignoredDatePeriod);

        filtered = filtered.filter(
          item =>
            item.ignore_at &&
            Date.parse(item.ignore_at) >= Date.parse(from) &&
            Date.parse(item.ignore_at) <= Date.parse(to),
        );
      }

      return self.filteredBySearch(filtered);
    },
    filteredItems(items, type) {
      let filtered = items;

      if (!self.categories[type].every(e => self.filters.categories[type].includes(e))) {
        filtered = filtered.filter(item => self.filters.categories[type].includes(item.category));
      }

      if (!self.subCategories[type].every(e => self.filters.subCategories[type].includes(e))) {
        filtered = filtered.filter(item =>
          self.filters.subCategories[type].includes(item.subcategory),
        );
      }

      filtered = self.filteredWithoutCategory(filtered);

      const sortedBy =
        self.filters.sortedBy === 'age' ? sortByAgeOptions[type] : self.filters.sortedBy;
      const sortedByList = sortedBy === 'category' ? ['category', 'subcategory'] : [sortedBy];
      const orderByList = Array(sortedByList.length).fill(self.filters.orderBy);

      return orderBy(filtered, sortedByList, orderByList);
    },
    sortNestedPOS(items) {
      if (self.filters.sortedBy === 'beverage') {
        return orderBy(items, ['beverage._name', 'beverage._producers_name'], self.filters.orderBy);
      }

      if (self.filters.sortedBy === 'serving_size') {
        return orderBy(items, 'beverage._containers_name', self.filters.orderBy);
      }

      if (self.filters.sortedBy === 'category') {
        return orderBy(items, ['pos.0.category', 'pos.0.subcategory'], [self.filters.orderBy]);
      }

      return orderBy(items, item => item.pos[0][self.filters.sortedBy], [self.filters.orderBy]);
    },
  }))
  .actions(self => ({
    createMapping: flow(function* (mapping) {
      mapping.forEach(item => {
        if (!item.beverage_id) {
          delete item.beverage_id;
        }
        if (!item.container_id) {
          delete item.container_id;
        }
      });

      try {
        const response = yield api.matchSKU(mapping);
        response.data.result.forEach(item => self.updateActiveById(item));
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    updateMapping: flow(function* (matchToUpdate, newItems) {
      const bartrackID = matchToUpdate.bartrack[0].id;

      const { pos } = newItems;
      if (Array.isArray(pos)) {
        const promises = pos.map(sku => {
          return self.connect(sku, { sku_id: bartrackID });
        });
        try {
          return yield Promise.all(promises);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    }),
    fetchPOSSKUs: flow(function* fetchPOSSKUs() {
      try {
        const response = yield api.getPOSSKUs();
        const skus = response.data.result;
        self.isLoaded = true;

        const { true: allIgnored, false: allActive } = groupBy(skus, 'ignore');

        self.allIgnored.replace(allIgnored);
        self.allActive.replace(allActive);
        self.setFilterCategories();

        self.isLoaded = true;
      } catch (err) {
        self.isLoaded = false;
        return Promise.reject(err);
      }
    }),
    patchPOSSKU: flow(function* patchPOSSKU(id, patch) {
      try {
        return yield api.patchPOSSKU(id, patch);
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    patchPOSSKUs: flow(function* patchPOSSKU(body, params) {
      try {
        return yield api.patchPOSSKUs(body, params);
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    connect: flow(function* connect(item, patch) {
      try {
        const response = yield self.patchPOSSKU(item.id, patch);
        const updatedItem = response.data.row;
        self.updateActiveById(updatedItem);
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    ignore: flow(function* ignore(items) {
      try {
        const response = yield api.ignoreSKU(items.map(item => item.id));
        const updatedItems = response.data.result;
        items.forEach(item => self.allActive.remove(item));
        updatedItems.forEach(item => self.allIgnored.push(item));
        return response;
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }),
    unignore: flow(function* unignore(ids) {
      try {
        const response = yield api.unignoreSKU(ids);
        const updatedItems = response.data.result;
        const updatedItemsIds = updatedItems.map(item => item.id);
        const updatedNodes = self.allIgnored.filter(item => updatedItemsIds.includes(item.id));
        updatedNodes.forEach(node => {
          self.allIgnored.remove(node);
        });
        self.allActive.push(...updatedItems);
        return response;
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }),
    unlink: flow(function* (ids) {
      try {
        const response = yield api.unmatchSKU([...ids]);

        response.data.result.forEach(posSKU => self.updateActiveById(posSKU));
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    updateActiveById(item) {
      const itemIndex = self.allActive.findIndex(sku => sku.id === item.id);
      if (itemIndex >= 0) {
        self.allActive[itemIndex] = item;
      }
    },
    setFilters(filters, type) {
      if (filters === null) {
        self.filters = {
          ...skuMappingStoreInitialState.filters,
          categories: self.allCategories,
          subCategories: self.allSubCategories,
        };
      } else {
        if (type) {
          self.filters = {
            ...self.filters,
            categories: {
              ...self.filters.categories,
              [type]: filters.categories || self.filters.categories[type],
            },
            subCategories: {
              ...self.filters.subCategories,
              [type]: filters.subCategories || self.filters.subCategories[type],
            },
          };
        }

        self.filters = {
          ...self.filters,
          priceRange: {
            from: filters.priceRange ? filters.priceRange.from : self.filters.priceRange.from,
            to: filters.priceRange ? filters.priceRange.to : self.filters.priceRange.to,
          },
          dateRange: filters.dateRange || self.filters.dateRange,
          dateFrom: filters.dateFrom || self.filters.dateFrom,
          dateTo: filters.dateTo || self.filters.dateTo,
          numberOfSales: filters.numberOfSales,
          sortedBy: filters.sortedBy || self.filters.sortedBy,
          orderBy: filters.orderBy || self.filters.orderBy,
        };
      }
    },

    resetFilters() {
      self.setFilters(null);
    },
    setSearch(value) {
      self.searchString = value;
    },
    setFilterCategories() {
      self.filters.categories = self.categories;
      self.filters.subCategories = self.subCategories;
    },
    setActivePOSSKUs(skus) {
      self.allActive.replace(skus);
      self.setFilterCategories();

      self.isLoaded = true;
    },
    setIgnoredPOSSKUs(skus) {
      self.allIgnored.replace(skus);
      self.setFilterCategories();
    },
  }));
