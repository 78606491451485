import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { rootStore } from 'models/root';
import { MobxProvider } from 'context/store';
import { ReferenceProvider } from 'context/reference';
import { DrawerProvider } from 'context/drawer';
import { FiltersProvider } from 'context/filters';
import { AbilityContext, ability } from 'services/casl';
import NetworkDetector from 'components/shared/NetworkDetector';
import SnackbarAction from 'components/shared/SnackbarAction';
import SnackbarCustom from 'components/shared/SnackbarCustom';
import { useReferences, useDrawerContextValues, useFiltersContextValues } from 'hooks';
import Routes from 'routes';
import theme from 'theme';

import 'assets/scss/index.scss';

const App = () => {
  const references = useReferences();
  const drawerValues = useDrawerContextValues();
  const filtersValues = useFiltersContextValues();
  const isProductionEnv = process.env.NODE_ENV === 'production';

  return (
    <StrictMode>
      {isProductionEnv && (
        <HelmetProvider>
          <Helmet>
            <script type="text/javascript">
              {`(function() {
                var walkme = document.createElement('script');
                walkme.type = 'text/javascript';
                walkme.async = true;
                walkme.src = '${process.env.REACT_APP_WALKME_SRC}'
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(walkme, s);
                window._walkmeConfig = {
                    smartLoad: true
                };
              })();`}
            </script>
          </Helmet>
        </HelmetProvider>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AbilityContext.Provider value={ability}>
          <MobxProvider value={rootStore}>
            <ThemeProvider theme={theme}>
              <ReferenceProvider value={references}>
                <DrawerProvider value={drawerValues}>
                  <FiltersProvider value={filtersValues}>
                    <SnackbarProvider
                      maxSnack={3}
                      autoHideDuration={3000}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      action={key => <SnackbarAction keyProp={key} />}
                      Components={{
                        custom: SnackbarCustom,
                      }}
                    >
                      <NetworkDetector />
                      <BrowserRouter>
                        <Routes />
                      </BrowserRouter>
                    </SnackbarProvider>
                  </FiltersProvider>
                </DrawerProvider>
              </ReferenceProvider>
            </ThemeProvider>
          </MobxProvider>
        </AbilityContext.Provider>
      </LocalizationProvider>
    </StrictMode>
  );
};

export default App;
