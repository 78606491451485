import { types } from 'mobx-state-tree';

export const POSSKU = types.model({
  id: types.integer,
  sku_id: types.maybeNull(types.number),
  item: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  subcategory: types.maybeNull(types.string),
  latest_price: types.optional(types.number, 0, [null, undefined]),
  latest_price_at: types.maybeNull(types.string),
  ignore: types.maybeNull(types.boolean),
  archived: types.boolean,
  earliest_sale_at: types.maybeNull(types.string),
  latest_sale_at: types.maybeNull(types.string),
  total_sales: types.maybeNull(types.number),
  ignored_at: types.maybeNull(types.string),
  ignored_by: types.maybeNull(types.number),
  matched_at: types.maybeNull(types.string),
  matched_by: types.maybeNull(types.number),
  beverage_id: types.maybeNull(types.number),
  container_id: types.maybeNull(types.number),
  _users_display_name_ignored_by: types.maybeNull(types.string),
  _users_display_name_matched_by: types.maybeNull(types.string),
});
