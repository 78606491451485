import React, { useState, useRef } from 'react';
import { Box, styled } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { phoneNumbersFormat } from 'utils/phoneNumbersFormat';

const PhoneInputCustom = ({ ...restProps }) => {
  const { isParentValidation, className, isPhoneNumberError } = restProps;
  const [isValid, setIsValid] = useState(true);
  const [country, setCountry] = useState('');
  const inputRef = useRef(null);

  const validatePhone = (event, country) => {
    if (event?.type === 'click') {
      setCountry(country?.countryCode);
      setIsValid(true);
      restProps?.onChange(event, true);
      inputRef?.current?.focus();
      return;
    }
    const phone = event?.target?.value;

    const isPhoneValid =
      country?.format === String(phone).replace(/[0-9]/g, '.') ||
      phoneNumbersFormat[country?.name] === String(phone).replace(/[0-9]/g, '.');
    setIsValid(isPhoneValid);
    if (restProps?.onChange) restProps.onChange(event, isPhoneValid);
  };

  return (
    <StyledRootBox>
      <PhoneInput
        {...restProps}
        inputProps={{
          ref: inputRef,
        }}
        placeholder="Phone Number"
        preferredCountries={['us']}
        masks={{ us: '...-...-....' }}
        isValid={isValid}
        country={country}
        onChange={(v, c, e) => validatePhone(e, c, v)}
        containerClass={`
          ${className ? className : ''} 
          ${isPhoneNumberError ? 'isPhoneNumberError' : ''}`}
      />
      <Box className={`${!isParentValidation && `errorBox`}`}>
        {!isParentValidation && !isValid && (
          <span style={{ color: 'red', fontSize: '12px' }}>Invalid phone number</span>
        )}
      </Box>
    </StyledRootBox>
  );
};

export default PhoneInputCustom;

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',
  textAlign: 'initial',
  '.react-tel-input .form-control': {
    width: '100%',
    height: '48px',
    transition: 'border 0.2s ease',
    border: `1px solid ${palette.background.overlay7}`,
    background: palette.background.main,
    color: palette.whiteEmphasis.high,
    '&:hover': {
      border: '1px solid white',
    },
    '&:focus': {
      border: '1px solid #ff7a00',
    },
  },
  '.react-tel-input .flag-dropdown': {
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    '.selected-flag': {
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  '.react-tel-input .flag-dropdown.open .selected-flag, .react-tel-input .flag-dropdown.open': {
    background: 'transparent',
    borderRadius: 0,
  },
  '.react-tel-input .country-list': {
    backgroundColor: palette.secondary.greyLight,
    '.country': {
      '&:hover': {
        backgroundColor: palette.secondary.light,
      },
    },
  },
  '.react-tel-input .country-list .country.highlight': {
    backgroundColor: palette.secondary.light,
  },
  '.form-control::placeholder': {
    color: palette.secondary.light,
    fontFamily: 'Montserrat',
  },
  '.react-tel-input .form-control.invalid-number, .react-tel-input .form-control.invalid-number:focus':
    {
      backgroundColor: palette.background.main,
      borderLeftColor: 'none',
    },
  '.react-tel-input.unbordered .form-control': {
    transition: 'none',
    border: `1px solid ${palette.background.overlay4}`,
  },
  '.react-tel-input.transparent .form-control': {
    background: 'transparent',
    border: `1px solid ${palette.whiteEmphasis.low}`,
    '&:hover': {
      border: '1px solid white',
    },
    '&:focus': {
      border: '1px solid #ff7a00',
    },
  },
  '.react-tel-input.transparent': {
    '.form-control.invalid-number, .react-tel-input .form-control.invalid-number:focus': {
      border: '1px solid red',
      background: 'transparent',
    },
    '.form-control::placeholder': {
      color: palette.whiteEmphasis.medium,
    },
    '&.isPhoneNumberError': {
      '.form-control': {
        border: '1px solid red',
      },
      '.form-control::placeholder': {
        color: 'red',
      },
    },
  },
  '.errorBox': {
    height: '12px',
  },
}));
