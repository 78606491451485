import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Box, IconButton, Divider, styled } from '@mui/material';
import { MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useStore } from 'hooks';
import { CalculatorIcon, LogoBarTrackIcon } from 'assets/icons';
import EstablishmentMenu from 'components/shared/EstablishmentMenu';
import NotificationsMenu from 'components/shared/NotificationsMenu';

const DesktopHeader = () => {
  const navigate = useNavigate();

  const { ui } = useStore();

  const openCalculator = useCallback(() => {
    ui.setModalVisibility('calculator', true);
  }, [ui]);

  return (
    <>
      <StyledLogoBox display={{ xs: 'none', md: 'flex' }} alignItems="center">
        <IconButton
          sx={{ margin: 0 }}
          color="inherit"
          aria-label="open drawer"
          onClick={ui.toggleMenu}
          edge="start"
        >
          <MenuOpenIcon
            className={clsx('menuIcon', {
              menuCloseIcon: !ui.isOpenedMenu,
            })}
          />
        </IconButton>
        <LogoBarTrackIcon className="logo" onClick={() => navigate(`/on-tap`)} />
      </StyledLogoBox>
      <Box width={1} display="flex" justifyContent="center" />
      <StyledControlsBox display="flex" alignItems="center">
        <IconButton
          edge="end"
          aria-label="system-status"
          aria-haspopup="true"
          className="icon"
          onClick={openCalculator}
        >
          <CalculatorIcon />
        </IconButton>
        <Divider orientation="vertical" flexItem />
        <NotificationsMenu />
        <Divider orientation="vertical" flexItem />
        <EstablishmentMenu />
      </StyledControlsBox>
    </>
  );
};

export default observer(DesktopHeader);

const StyledLogoBox = styled(Box)(() => ({
  columnGap: '8px',

  '.menuIcon': {
    transition: '.3s',
    transform: 'scaleX(1)',
  },
  '.menuCloseIcon': {
    transform: 'scaleX(-1)',
  },

  '.logo': {
    cursor: 'pointer',
  },
}));

const StyledControlsBox = styled(Box)(({ theme: { palette } }) => ({
  '& hr': {
    height: 32,
    marginRight: 21,
  },

  '.icon': {
    color: palette.secondary.lightest,
    marginRight: 19,
  },

  '.notificationsIcon': {
    color: palette.secondary.lightest, // keep for activation - disabling until they come into effect
    marginRight: 19,
  },
}));
