import { types } from 'mobx-state-tree';

const Context = types.model({
  cable_color: types.maybeNull(types.string),
  cable_length_m: types.maybeNull(types.number),
  caption: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  sample_age_seconds: types.maybeNull(types.number),
  temp_c: types.maybeNull(types.number),
  gateway_id: types.maybeNull(types.integer),
});

const Message = types.model({
  alert_caption: types.maybeNull(types.string),
});

export const Condition = types.model({
  id: types.integer,
  alarm_id: types.maybeNull(types.integer),
  archived: types.boolean,
  context: types.maybeNull(Context),
  criteria: types.maybeNull(types.string),
  establishment_id: types.maybeNull(types.integer),
  happened: types.maybeNull(types.string),
  happened_from: types.maybeNull(types.string),
  happened_to: types.maybeNull(types.string),
  latest_notification_at: types.maybeNull(types.string),
  latest_trigger_at: types.maybeNull(types.string),
  message: types.maybeNull(Message),
  snooze_for_seconds: types.maybeNull(types.number),
  type_code: types.maybeNull(types.number),
  criteria_value: types.maybeNull(types.number),
  _closed: types.maybeNull(types.boolean),
  _alarms_operand_id: types.maybeNull(types.number),
});
