import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom';
import { Box, styled, Tab, Tabs as MuiTabs, alpha } from '@mui/material';

import findIndex from 'lodash/findIndex';

const Tabs = ({
  isNavTabs = false,
  tabs,
  currentTab,
  className,
  onTabChange,
  parentControl,
  isFullWidth,
  isSquareTabs,
  isNativeView = false,
  centered = true,
  variant = 'standard',
  scrollButtons = false,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchRoutes(tabs, location);

  const { route = null } = match || {};
  const [value, setValue] = useState(() => {
    if (isNavTabs && Array.isArray(tabs) && tabs.length > 0 && route && route.path) {
      return findIndex(tabs, { path: route.path });
    }
    return 0;
  });

  useEffect(() => {
    if (isNavTabs && Array.isArray(tabs) && tabs.length > 0 && location.pathname) {
      const index = findIndex(tabs, ({ path }) => location.pathname.includes(path));
      if (index >= 0) {
        setValue(index);
        setActiveTab && setActiveTab(tabs[index]?.path);
      }
    } else {
      setValue(currentTab || value || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate, isNavTabs, tabs]);

  const handleChange = useCallback(
    newValue => {
      if (!parentControl) {
        setValue(newValue);
        if (onTabChange && typeof onTabChange === 'function') {
          onTabChange(newValue);
        }
      } else {
        onTabChange(newValue);
      }
    },
    [onTabChange, parentControl],
  );

  useEffect(() => {
    if (currentTab !== null && currentTab !== undefined && !parentControl) {
      handleChange(currentTab);
    } else if (currentTab !== null && currentTab !== undefined && parentControl) {
      setValue(currentTab);
    }
  }, [currentTab, parentControl, handleChange]);

  const TabsComponent = isNativeView ? StyledNativeTabs : StyledTabs;
  const TabComponent = isNativeView ? StyledNativeTab : StyledTab;

  return (
    <>
      <TabsComponent
        value={value}
        onChange={(e, value) => handleChange(value)}
        aria-label="simple tabs example"
        centered={centered}
        isFullWidth={isFullWidth}
        isSquareTabs={isSquareTabs}
        className={className}
        variant={variant}
        scrollButtons={scrollButtons}
        // allowScrollButtonsMobile={scrollButtons}
      >
        {tabs.map(({ label, path, disabled, icon }, index) => (
          <TabComponent
            key={index}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            label={label}
            disabled={Boolean(disabled)}
            icon={icon}
            onClick={e => {
              if (isNavTabs) {
                e.preventDefault();
                navigate(path);
              }
            }}
          />
        ))}
      </TabsComponent>
      {!isNavTabs &&
        tabs.map(({ panel }, index) => (
          <Box
            key={index}
            index={index}
            id={`tabpanel-${index}`}
            value={value}
            hidden={value !== index}
            role="tabpanel"
            aria-labelledby={`tab-${index}`}
          >
            {value === index && <div>{panel}</div>}
          </Box>
        ))}
    </>
  );
};

export default Tabs;

export const StyledNativeTab = styled(Tab, {
  shouldForwardProp: props => props !== 'className',
})(({ className }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  textTransform: 'none',
  minWidth: '195px',
  ...className,
}));

export const StyledTab = styled(Tab, {
  shouldForwardProp: props => props !== 'className',
})(({ className, theme }) => ({
  minWidth: 160,
  height: '36px',
  minHeight: '36px',
  fontSize: 18,
  fontWeight: 500,
  textTransform: 'capitalize',
  border: `1px solid ${alpha(theme.palette.white, 0.5)} ! important`,
  '&:first-of-type': {
    borderRadius: '5px 0 0 5px',
  },
  '&:last-of-type': {
    borderRadius: '0 5px 5px 0',
  },
  '&.Mui-selected': {
    color: `${alpha(theme.palette.black, 0.87)} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    background: theme.palette.primary.main,
  },

  '@media(max-width: 599px)': {
    fontSize: 12,
    minWidth: 91,
  },

  '&.MuiTab-textColorInherit': {
    opacity: 0.5,
    transition: '.3s',
    '&:hover': {
      opacity: 1,
    },
  },

  ...className,
}));

export const StyledNativeTabs = styled(MuiTabs, {
  shouldForwardProp: props => props !== 'isFullWidth' && props !== 'isSquareTabs',
})(() => ({ justifyContent: 'flex-start' }));

export const StyledTabs = styled(MuiTabs, {
  shouldForwardProp: props => props !== 'isFullWidth' && props !== 'isSquareTabs',
})(({ theme, isFullWidth, isSquareTabs }) => {
  const isFullWidthStyles = isFullWidth ? { flexGrow: 1 } : {};
  const isSquareTabsStyles = isSquareTabs ? { borderRadius: '0 !important' } : {};
  return {
    color: theme.palette.white,
    minHeight: 36,

    '& .MuiButtonBase-root': {
      minHeight: '36px',
    },

    '& .MuiTabs-indicator': {
      display: 'none',
    },
    ...isFullWidthStyles,
    ...isSquareTabsStyles,
  };
});
