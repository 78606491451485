import React, { useCallback, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import { Box, InputAdornment, TextField, IconButton } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

import { useLocation } from 'react-router-dom';

const SearchField = ({
  name = 'search',
  placeholder = '',
  initialValue = '',
  onChange,
  onClear,
  maxWidth = '100%',
  autoFocus = false,
  triggerFocus = 0,
  variant = 'filled',
  resetOnPathChange = false,
  ...restProps
}) => {
  const location = useLocation();

  const [value, setValue] = useState(initialValue);
  const debouncedOnChange = useDebouncedCallback(onChange, 500);
  const inputRef = useRef(null);

  const onValueChange = useCallback(
    value => {
      setValue(value);
      debouncedOnChange(value);
    },
    [debouncedOnChange],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (triggerFocus > 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [triggerFocus]);

  const resetValue = useCallback(() => {
    setValue('');
    onClear();
  }, [onClear]);

  useEffect(() => {
    if (resetOnPathChange) {
      resetValue();
    }
  }, [location.pathname, resetOnPathChange, resetValue]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="search"
      {...restProps}
    >
      <TextField
        inputRef={inputRef}
        variant={variant}
        placeholder={placeholder}
        value={value}
        onChange={e => onValueChange(e.target.value)}
        fullWidth
        sx={{ maxWidth }}
        autoFocus={autoFocus}
        onKeyDown={e => e.stopPropagation()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          name,
          autoComplete: 'off',
          ...(value && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={resetValue}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        className="mb-0"
        disabled={restProps.disabled}
      />
    </Box>
  );
};

export default observer(SearchField);
