import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore, useToast, useMediaQueries } from 'hooks';
import { stringUtilities } from 'utils';
import { messages } from 'config/messages';

import Dialog from 'components/shared/dialogs/Dialog';

import { schema } from './settings';
import PhoneInputCustom from 'components/shared/PhoneInput';

const MissingUserDetailsDialog = ({ open }) => {
  const { isMobileOrSmaller } = useMediaQueries();
  const { ui, userStore, usersStore } = useStore();
  const { successToast, errorToast } = useToast();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    resetField,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    defaultValues: {
      first_name: userStore.profile.first_name,
      last_name: userStore.profile.last_name,
      phone_number: stringUtilities.formatPhone(userStore.profile.phone_number) || ' ',
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const title = 'Missing Account Details';
  const onSubmit = useCallback(
    async ({ first_name, last_name, phone_number }) => {
      try {
        setIsSubmitting(true);
        const response = await usersStore.updateUser(userStore.profile.id, {
          first_name: first_name,
          last_name: last_name,
          phone_number: phone_number.replace(/[\s()-]/g, ''),
        });

        if (response) {
          successToast(response.message);
          ui.setModalVisibility('missingUserDetails', false);
        }
      } catch (err) {
        errorToast(messages.validation.PASSWORD_RESET_FAILED);
      }
      setIsSubmitting(false);
    },
    [errorToast, usersStore, successToast, userStore.profile.id, ui],
  );

  useEffect(() => {
    setIsFormValid(Object.keys(errors).length ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(errors).length]);

  const isPhoneNumberValid = isValid => {
    if (isValid) {
      clearErrors('phone_number');
    } else {
      resetField('phone_number');
      setError('phone_number', { type: 'custom', message: 'Invalid phone number' });
    }
  };

  return (
    <StyledRootDialog
      open={open}
      title={title}
      maxWidth={false}
      disableMobileFullscreen
      preventClosing
      hideCloseIcon
    >
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid
              container
              item
              xs={12}
              sm={7}
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              {isMobileOrSmaller && (
                <Typography className="title" mb={3}>
                  {title}
                </Typography>
              )}
              <Typography className="description" mb={3}>
                We noticed that your account is missing some required information. BarTrack needs
                these details to help keep track of who does what within our application.
              </Typography>
              <Typography className="description" mb={3}>
                Please take a moment to fill out the missing details. We promise not to share your
                information with third parties.
              </Typography>
              {!isMobileOrSmaller && <Typography className="description">Thank you!</Typography>}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={5}
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} mb={1}>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="first_name"
                        type={'text'}
                        label="First Name*"
                        variant="outlined"
                        fullWidth
                        helperText={errors?.first_name?.message}
                        error={Boolean(errors?.first_name)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={1}>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        name="last_name"
                        type={'text'}
                        label="Last Name*"
                        variant="outlined"
                        fullWidth
                        helperText={errors?.last_name?.message}
                        error={Boolean(errors?.last_name)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mb={1}>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Box>
                        <PhoneInputCustom
                          className="unbordered"
                          isParentValidation={true}
                          value={userStore?.profile?.phone_number}
                          onChange={({ target }, isValid) => {
                            setValue('phone_number', target?.value);
                            onChange(target?.value);
                            isPhoneNumberValid(isValid);
                          }}
                        />
                        <Box height={6}>
                          {errors.phone_number && (
                            <span style={{ color: 'red', fontSize: '12px' }}>
                              {errors.phone_number.message}
                            </span>
                          )}
                        </Box>
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className="capitalize"
            type="submit"
            variant="contained"
            color="primary"
            autoFocus
            disabled={isSubmitting || !isFormValid}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </StyledRootDialog>
  );
};

export default observer(MissingUserDetailsDialog);

const StyledRootDialog = styled(Dialog)(({ theme: { breakpoints, palette } }) => ({
  '.MuiDialogContent-root': {
    'overflow-x': 'hidden',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
  },
  '&.MuiPaper-root': {
    borderRadius: 8,
    maxWidth: 744,
    [breakpoints.down('sm')]: {
      background: palette.secondary.greyLight,
      borderRadius: 16,
      maxWidth: 280,
    },
  },
  '& .MuiDialogTitle-root': {
    justifyContent: 'left',
    '&  .MuiTypography-root': {
      fontSize: 20,
      fontWeight: 500,
      marginTop: 12,
    },
  },
  '& .MuiDialogActions-root': {
    [breakpoints.up('sm')]: {
      justifyContent: 'right',
    },

    '& button': {
      borderRadius: 5,
    },
  },
  '.title': {
    fontSize: 16,
    fontWeight: 500,
  },
  '.description': {
    [breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));
