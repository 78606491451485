import { types } from 'mobx-state-tree';

export const Notification = types.model({
  id: types.integer,
  condition_id: types.maybeNull(types.integer),
  role_user_id: types.maybeNull(types.integer),
  status_code: types.maybeNull(types.integer),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  archived: types.optional(types.boolean, false, [undefined, '', null]),
  _alarms_alert_id: types.maybeNull(types.integer),
  _alarms_alert_caption: types.maybeNull(types.string),
  _conditions_happened_from: types.maybeNull(types.string),
  _conditions_latest_trigger_at: types.maybeNull(types.string),
  _gateways_id: types.maybeNull(types.integer),
  _gateways_name: types.maybeNull(types.string),
  _gateways_cooler_id: types.maybeNull(types.integer),
  _coolers_name: types.maybeNull(types.string),
  _conditions__closed: types.optional(types.boolean, false, [undefined, '', null]),
  _conditions_alarm_id: types.maybeNull(types.integer),
  _conditions_criteria: types.maybeNull(types.string),
});

export const UserDevice = types.model({
  archived: types.boolean,
  created_at: types.string,
  created_by: types.integer,
  device_class: types.string,
  device_name: types.string,
  id: types.integer,
  device_fingerprint: types.maybeNull(types.union(types.string, types.number)),
  notifications_muted: types.boolean,
  operating_system: types.string,
  user_id: types.integer,
});
