import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiSvgIcon = {
  styleOverrides: {
    colorSecondary: {
      color: alpha(palette.white, 0.6),
    },
    root: {
      '&.green': {
        color: palette.status.green,
      },
      '&.red': {
        color: palette.status.red,
      },
      '&.grey': {
        color: alpha(palette.white, 0.38),
      },
      '&.clickable': {
        cursor: 'pointer',
      },
    },
  },
};

export default MuiSvgIcon;
