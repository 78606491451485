import { types, flow } from 'mobx-state-tree';
import { sortBy, groupBy } from 'lodash';
import { differenceInDays } from 'date-fns';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { normalize } from 'utils/diacriticNormalizer';
import dateUtilities from 'utils/dateUtilities';
import { DAY_MS } from 'config/constants';

import {
  BRU,
  DeviceConnectivityHistory,
  Gateway,
  LineSensor,
  Sensor,
  TemperatureSensor,
  EstablishmentSensors,
  SensorMetadata,
  PourEvent,
} from 'models/types';

export const topologyManagementInitialState = {
  isLoaded: false,
  gateways: [],
  state: 'done',
  updated: null,
  brus: [],
  sensors: [],
  temperatureSensors: [],
  lineSensors: [],
  isMultiSelect: false,
  selectedLineIds: [],
  establishments_sensors: null,
  searchString: '',
  filters: {
    orderBy: 'asc',
    excludedCoolersIds: [],
    excludedGatewaysIds: [],
    excludedBRUsIds: [],
    selectedLineIDs: [],
  },
  selectedCoolerID: null,
  selectedGatewayID: null,
  period: {
    from: null,
    to: null,
  },
  dataset: [],
  connectivityHistory: {
    gateways: [],
    brus: [],
    sensors: [],
  },
  selectedGatewayId: null,
  selectedSensorMetadata: null,
  recentPours: [],
};

const SensorWithView = Sensor.views(self => ({
  get line() {
    const root = getRootStore();
    const lineSensors = root.topologyManagementStore.lineSensors.find(
      el => el.sensor_id === self.id,
    );

    return root.linesStore.lines.find(line => line.id === lineSensors?.line_id);
  },
}));

export const topologyManagementModel = types
  .model({
    isLoaded: types.boolean,
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    gateways: types.array(Gateway),
    brus: types.array(BRU),
    sensors: types.array(SensorWithView),
    temperatureSensors: types.array(TemperatureSensor),
    lineSensors: types.array(LineSensor),
    isMultiSelect: types.boolean,
    selectedLineIds: types.array(types.number),
    establishments_sensors: types.maybeNull(EstablishmentSensors),
    searchString: types.maybeNull(types.string),
    filters: types.maybeNull(
      types.model({
        excludedCoolersIds: types.array(types.number),
        excludedGatewaysIds: types.array(types.number),
        excludedBRUsIds: types.array(types.number),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
        selectedLineIDs: types.array(types.number),
      }),
    ),
    selectedCoolerID: types.maybeNull(types.number),
    selectedGatewayID: types.maybeNull(types.number),
    period: types.model({
      from: types.maybeNull(types.string),
      to: types.maybeNull(types.string),
    }),
    connectivityHistory: DeviceConnectivityHistory,
    selectedGatewayId: types.maybeNull(types.integer),
    selectedSensorMetadata: types.maybeNull(SensorMetadata),
    recentPours: types.array(PourEvent),
  })
  .views(self => ({
    get sortedGateways() {
      const grouped = groupBy(self.gateways, 'cooler_id');
      const result = [];
      Object.values(grouped).forEach(items => {
        if (items.length > 1) {
          const filtered = items.filter(item => item.type_id && item.type_id !== 0);
          result.push(...filtered);
        } else {
          result.push(...items);
        }
      });

      return sortBy(result, '_coolers_name');
    },

    get mappedEquipment() {
      const root = getRootStore();

      const mergedGateways = self.gateways.map(gateway => {
        const gateway_ES =
          self.establishments_sensors.gateways?.find(g => g.gateway_id === gateway.id) || {};
        const mergedBrus = self.brus
          .filter(bru => bru._gateways_id === gateway.id)
          .map(bru => {
            const bru_ES = gateway_ES?.brus?.find(b => b.bru_id === bru.id) || {};
            const sensors = self.sensors.filter(s => s.bru_id === bru.id);
            const unmappedSensors = sensors.filter(s => !s.line);
            return {
              ...bru,
              ...bru_ES,
              latest_pour: sensors
                .map(s => s.latest_pour_poured_at)
                .filter(Boolean)
                .sort()
                .reverse()[0],
              sensors: sensors.sort((a, b) => a.bru_sensor_address - b.bru_sensor_address),
              unmappedSensors,
            };
          });

        const mergedTempSensors = self.temperatureSensors
          .filter(ts => ts._gateways_id === gateway.id)
          .map(tempSensor => {
            const tempSensor_ES = gateway_ES?.temp_sensors?.find(s => s.id === tempSensor.id);
            return {
              ...tempSensor_ES,
              ...tempSensor,
            };
          });

        const groupedBRUs = groupBy(mergedBrus, 'gateway_bru_address');

        const replacedBRUs = Object.values(groupedBRUs).map(bruArr => {
          const sortedByEnumeratedAt = bruArr
            .sort((a, b) => b.id - a.id)
            .sort((a, b) => Date.parse(b.enumerated_at) - Date.parse(a.enumerated_at));

          const sortedByActiveSensorsEnumeratedAt = bruArr
            .map(bru => {
              if (bru.sensors.length && bru.sensors.some(s => s.line)) {
                const enumeratedSensors = bru.sensors
                  .filter(s => s.line)
                  .map(s => s.enumerated_at)
                  .sort((a, b) => Date.parse(b) - Date.parse(a));

                return {
                  ...bru,
                  sensorsEnumeratedAt: enumeratedSensors[0],
                };
              } else return null;
            })
            .filter(Boolean)
            .sort((a, b) => Date.parse(b.sensorsEnumeratedAt) - Date.parse(a.sensorsEnumeratedAt));

          if (
            sortedByActiveSensorsEnumeratedAt[0] &&
            sortedByActiveSensorsEnumeratedAt[0].id !== sortedByEnumeratedAt[0].id
          ) {
            return {
              ...sortedByActiveSensorsEnumeratedAt[0],
              replacementDetected: true,
              replace_bru_id: sortedByEnumeratedAt[0].id,
            };
          } else {
            return { ...sortedByEnumeratedAt[0], replacementDetected: false };
          }
        });

        return {
          ...gateway_ES,
          ...gateway,
          brus: replacedBRUs.sort((a, b) => a.gateway_bru_address - b.gateway_bru_address),
          temp_sensors: mergedTempSensors.sort((a, b) =>
            a._gateway_types_sensors_caption?.localeCompare(b._gateway_types_sensors_caption),
          ),
          bru_sensors_count: replacedBRUs
            ?.map(({ sensors }) => sensors.length)
            .reduce((sum, curr) => sum + curr, 0),
        };
      });

      return root.coolersStore.all.map(cooler => {
        const gateways = groupBy(mergedGateways, 'cooler_id')[cooler.id] || [];
        return {
          ...cooler,
          gateways: gateways.map(g => {
            const gw_types = [];

            if (g.brus?.length) gw_types.push('bru');
            if (g.temp_sensors?.length) gw_types.push('temperature');
            if (gw_types.length === 0) gw_types.push('empty');

            return {
              ...g,
              gw_types,
            };
          }),
        };
      });
    },

    get filteredEquipment() {
      let result = self.mappedEquipment || [];

      if (self.searchString) {
        const searchArray = self.searchString.toLowerCase().split(' ');

        result = result
          .map(cooler => ({
            ...cooler,
            gateways: cooler.gateways
              .map(gateway => ({
                ...gateway,
                brus: gateway.brus
                  .map(bru => ({
                    ...bru,
                    sensors: bru.sensors.filter(sensor => {
                      if (!sensor.line?.item?._beverages_name) return false;

                      const every = searchArray.every(
                        element =>
                          normalize(sensor.line?.item?.beverage?._name)
                            .toLowerCase()
                            .includes(element) ||
                          normalize(sensor.line?.item?.beverage?._producers_name)
                            .toLowerCase()
                            .includes(element),
                      );

                      return every;
                    }),
                  }))
                  .filter(({ sensors }) => !!sensors.length),
              }))
              .filter(({ brus }) => !!brus.length),
          }))
          .filter(({ gateways }) => !!gateways.length);
      }

      result = result.filter(({ id }) => !self.filters.excludedCoolersIds.includes(id));

      result = result
        .map(cooler => {
          const filteredGateways = cooler.gateways
            .map(gateway => ({
              ...gateway,
              brus: gateway?.brus?.filter(({ id }) => !self.filters.excludedBRUsIds.includes(id)),
            }))
            .filter(({ id }) => !self.filters.excludedGatewaysIds.includes(id));

          return {
            ...cooler,
            gateways: filteredGateways.map(g => {
              const gw_types = [];

              if (g.brus?.length) gw_types.push('bru');
              if (g.temp_sensors?.length) gw_types.push('temperature');
              if (gw_types.length === 0) gw_types.push('empty');

              return {
                ...g,
                gw_types,
              };
            }),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      return self.filters.orderBy === 'asc' ? result : result.reverse();
    },

    get unmappedEquipment() {
      return self.gateways.filter(gw => !gw.cooler_id);
    },

    get mappedLines() {
      if (!self.gateways.length) return [];

      const root = getRootStore();
      const allLines = root.linesStore.all.map(e => e);
      const mappedLinesIds = self.lineSensors.map(ls => ls.line_id);
      return allLines.filter(line => mappedLinesIds.includes(line.id));
    },

    get isDefaultFilters() {
      return (
        self.filters.orderBy === 'asc' &&
        self.filters.excludedCoolersIds.length === 0 &&
        self.filters.excludedGatewaysIds.length === 0 &&
        self.filters.excludedBRUsIds.length === 0 &&
        self.filters.selectedLineIDs.length === 0
      );
    },

    get selectedSensor() {
      if (!self.selectedLineIds.length) return null;
      const lineSensor = self.lineSensors.find(({ line_id }) =>
        self.selectedLineIds.includes(line_id),
      );

      return self.mappedEquipment
        .flatMap(({ gateways }) =>
          gateways.flatMap(({ brus }) => brus.flatMap(({ sensors }) => sensors)),
        )
        .find(({ id }) => lineSensor.sensor_id === id);
    },

    get hasMappedGateways() {
      return Boolean(self.gateways.filter(gw => !!gw.cooler_id)?.length);
    },

    get equipmentBySelectedCooler() {
      return self.filteredEquipment.filter(({ id }) => id === self.selectedCoolerID);
    },

    get bins() {
      // Used for API and calculation number of X axis chart labels
      return 96;
    },
    get pours() {
      return self.recentPours.map(e => e);
    },
  }))
  .actions(self => ({
    fetchDeviceConnectivityHistory: flow(function* () {
      try {
        self.isLoaded = false;

        let { from, to } = self.period;

        if (!from || !to) {
          ({ from, to } = dateUtilities.getDatesByPeriod('1'));
        }

        const response = yield api.getDeviceConnectivityHistory({
          gateway_id: self.selectedGatewayId,
          from_ts: from,
          to_ts: to,
          bins: self.bins,
        });
        self.setConnectivityHistory(response.data?.result);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      } finally {
        self.isLoaded = true;
      }
    }),

    fetchSensorDiagnosticsInfo: flow(function* (params) {
      self.state = 'pending';
      try {
        self.setSensorMetaInfo(null);
        const response = yield api.getSensorDiagnosticInfo(params);
        self.setSensorMetaInfo(response.data);
        self.state = 'done';
        return response.data;
      } catch (error) {
        self.state = 'done';
        self.setSensorMetaInfo({
          latest_pour: null,
          latest_calibration: null,
          pga_gain: null,
          sensor_vfr: null,
          volume_scale_factor: null,
          calibrated_tof: null,
          signal_strength: null,
          sensor_temp_c: null,
        });
      }
    }),
    fetchEstablishmentsSensors: flow(function* () {
      self.state = 'pending';
      try {
        const response = yield api.getEstablishmentsSensorsData();
        self.state = 'done';
        self.setEstablishmentSensors(response.data?.result[0]);
      } catch (error) {
        self.state = 'done';
        return Promise.reject(error);
      }
    }),
    calibrateSensorAccuracy: flow(function* (body) {
      const root = getRootStore();

      try {
        const response = yield api.calibrateSensorAccuracy(body);
        const sensor = self.getEnumeratedSensorByPositionalAddress(body);

        if (sensor) {
          const sensorIndex = self.sensors.findIndex(s => s.id === sensor.id);

          self.sensors[sensorIndex].calibration_status_code = response?.data?.result.status;
          self.sensors[sensorIndex]._users_display_name_latest_calibration_by =
            root.userStore?.profile?.fullName;
        }

        return response?.data?.result.status;
      } catch (error) {
        return Promise.reject(error);
      }
    }),
    calibrateSensorValues: flow(function* (body) {
      self.state = 'pending';

      try {
        const response = yield api.calibrateSensorValues(body);

        self.state = 'done';

        return response?.data;
      } catch (error) {
        self.state = 'error';
        return Promise.reject(error);
      }
    }),

    patchGateway: flow(function* (id, body) {
      try {
        const response = yield api.patchGateways(body, id);
        const updatedGateway = response.data?.row;

        self.gateways.replace([
          ...self.gateways.filter(e => e.id !== updatedGateway.id),
          updatedGateway,
        ]);
        return updatedGateway;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    replaceBRU: flow(function* (body) {
      try {
        const response = yield api.replaceBRU(body);

        const { connected, disconnected } = response?.data?.result;

        const disconnectedLineSensorsIDs = disconnected._line_sensors.map(({ id }) => id);
        self.lineSensors.replace([
          ...self.lineSensors.filter(({ id }) => !disconnectedLineSensorsIDs.includes(id)),
          ...disconnected._line_sensors,
          ...connected._line_sensors,
        ]);

        self.brus.replace([
          ...self.brus.filter(({ id }) => disconnected._bru.id !== id),
          disconnected._bru,
          connected._bru,
        ]);

        return response?.data?.result;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    getLastPour() {
      return new Date(Date.now() - DAY_MS * 3).toISOString();
    },

    clearSensorsLines: flow(function* () {
      const root = getRootStore();

      try {
        const response = yield api.clearSensorsLines(self.selectedLineIds);

        const {
          disconnected_items,
          disconnected_sensors,
          disconnected_taps,
          removed_queued_items,
          stopped_cleaning,
        } = response?.data?.result;

        if (disconnected_sensors.count > 0) {
          disconnected_sensors.disconnected._line_sensor.forEach(self.updateLineSensor);
        }

        if (disconnected_taps.count > 0) {
          disconnected_taps.disconnected._line_taps.forEach(root.lineTapsStore.updateLineTap);
          disconnected_taps.disconnected._taps.forEach(root.tapsStore.updateTap);
        }

        if (disconnected_items.count > 0) {
          disconnected_items.disconnected._item_lines.forEach(root.itemLinesStore.updateItemLine);
          disconnected_items.disconnected._items.forEach(root.itemsStore.updateItem);
          disconnected_items.disconnected._lines.forEach(root.linesStore.updateLine);
        }

        if (removed_queued_items.rowCount > 0) {
          removed_queued_items.removed._item_events.forEach(
            root.itemEventsStore.updateOrInsertItemEvent,
          );

          const removedItemLinesIds = removed_queued_items.removed._item_lines.map(({ id }) => id);
          removedItemLinesIds.forEach(root.itemLinesStore.removeItemLineById);

          removed_queued_items.removed._items.forEach(_item => {
            const item = root.itemsStore.all.find(item => item.id === _item.id);

            root.itemsStore.updateItem({
              ...item,
              ..._item,
              _queued_count: item._queued_count - 1,
            });
          });
        }

        if (stopped_cleaning.rowCount > 0) {
          stopped_cleaning.stopped.forEach(root.cleaningsStore.updateOrInsert);
        }

        self.setSelectedLines([]);
        return response?.data?.result;
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    connectNewLineToSensor: async (sensor, data) => {
      try {
        const root = getRootStore();
        const body = {
          ...data,
          establishment_id: root.userStore.currentRole._establishment_id,
          cooler_id: self.getCoolerIdByGatewayId(sensor?._gateways_id),
          status_code: 0,
          archived: false,
        };
        const newLineResponse = await api.createLine(body);

        const newTapResponse = await api.createTap({
          establishment_id: body.establishment_id,
          identifier: `Tap-${body.sort_value}`,
          status_code: 0,
          archived: false,
        });

        const connectLineTapResponse = await api.connectNewLineTap([
          {
            line_id: newLineResponse?.data?.id,
            tap_id: newTapResponse?.data?.id,
          },
        ]);

        root.lineTapsStore.setLineTaps([
          ...root.lineTapsStore.lineTaps,
          ...connectLineTapResponse.data?.result?.connected?._line_taps,
        ]);

        if (newLineResponse?.data?.row) {
          root.linesStore.updateLine(newLineResponse?.data?.row);
        }

        const connectLineSensorResponse = await api.connectNewLineSensor({
          line_id: newLineResponse?.data?.id,
          sensor_id: sensor.id,
        });

        if (connectLineSensorResponse.data?.result?.connected?._line_sensors) {
          self.updateLineSensor(
            connectLineSensorResponse.data?.result?.connected?._line_sensors[0],
          );
        }

        return newLineResponse?.data?.row;
      } catch (err) {
        return Promise.reject(err);
      }
    },

    updateExistingLine: async ({ sensor, lineNumber, tapNumber, beverage }) => {
      const { itemLineController, linesStore } = getRootStore();

      if (beverage) {
        const newItem = beverage.items.find(item => item.status_code === 2) || beverage.items[0];

        if (newItem.id !== sensor?.line?.item?.id) {
          await itemLineController.connectNewItem(sensor.line.id, newItem.id, 2);
        }
      } else if (sensor.line?.item) {
        await itemLineController.disconnectCurrentItem(sensor.line.id, 2);
      }

      if (sensor.line.identifiers?.taps[0]?.numerical !== +tapNumber) {
        await self.updateTapNumber({
          tap_id: sensor.line.lineTap.tap_id,
          line_id: sensor.line.id,
          tap_body: {
            identifier: `Tap-${tapNumber}`,
          },
          line_body: {
            identifiers: {
              line: {
                prefix: 'Line',
                numerical: Number(lineNumber),
              },
              taps: [
                {
                  prefix: 'Tap',
                  numerical: Number(tapNumber),
                },
              ],
            },
            sort_value: Number(lineNumber),
          },
        });
      }
      if (sensor.line.identifiers.line.numerical !== +lineNumber) {
        await linesStore.patchLine(sensor.line.id, {
          identifiers: {
            line: {
              prefix: 'Line',
              numerical: Number(lineNumber),
            },
            taps: [
              {
                prefix: 'Tap',
                numerical: Number(tapNumber),
              },
            ],
          },
        });
      }
    },

    updateTapNumber: async data => {
      try {
        const root = getRootStore();

        await root.tapsStore.patchTap(data.tap_id, data.tap_body);
        await root.linesStore.patchLine(data.line_id, data.line_body);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    getCoolerIdByGatewayId(id) {
      return self.gateways.find(g => g.id === id)?.cooler_id;
    },

    getEquipmentInfoByPour(pour) {
      if (!pour) return;
      const gatewayId = +pour.gateway_id;
      const bruAddr = pour.bru_addr;
      const sensorAddr = pour.sensor_addr;

      const gateway = self.gateways.find(g => g.id === gatewayId);
      const cooler = self.mappedEquipment.find(c => c.id === gateway.cooler_id);
      const coolerGateway = cooler.gateways.find(g => g.id === gatewayId);
      const bru = coolerGateway.brus.find(b => b.addr === bruAddr);
      const sensor = bru.sensors.find(s => s.bru_sensor_address === sensorAddr);

      return Object.assign(sensor, {
        coolerName: cooler?.name || '-',
        bruSticker: bru?.sticker || '-',
        sensorId: sensor?.id || '-',
      });
    },

    getUsedIdentifiersBySensor(_sensor) {
      const coolerId = self.gateways.find(g => g.id === _sensor?._gateways_id)?.cooler_id;
      const cooler = self.mappedEquipment.find(cooler => cooler.id === coolerId);

      if (!_sensor?._gateways_id || !coolerId) return [];

      return cooler?.gateways
        ?.flatMap(g => g?.brus?.flatMap(({ sensors }) => sensors))
        .filter(sensor => Boolean(sensor?.line) && sensor.id !== _sensor.id)
        .map(({ id, line: { identifiers } }) => ({
          id,
          lineId: identifiers.line.numerical,
          tapId: identifiers?.taps[0]?.numerical,
        }));
    },

    getFilteredItemsByKey(key) {
      switch (key) {
        case 'cooler':
          return self.filteredEquipment;
        case 'gateway':
          return self.filteredEquipment.flatMap(({ gateways }) => gateways);
        case 'bru':
          return self.filteredEquipment.flatMap(({ gateways }) =>
            gateways.flatMap(({ brus }) => brus),
          );
        case 'sensor':
          return self.filteredEquipment.flatMap(({ gateways }) =>
            gateways.flatMap(({ brus }) => brus).flatMap(({ sensors }) => sensors),
          );
        default:
          return [];
      }
    },

    getEnumeratedSensorByPositionalAddress(body) {
      const gw = self.gateways.find(g => g.identifier === body.gateway_identifier);
      const bru = self.brus.find(
        b =>
          b.enumerated &&
          b._gateways_id === gw?.id &&
          b.gateway_bru_address === body.gateway_bru_address,
      );

      return self.sensors.find(
        s =>
          s.enumerated && s.bru_id === bru?.id && s.bru_sensor_address === body.bru_sensor_address,
      );
    },

    setBRUs(rows) {
      self.brus.replace(rows);
    },

    setGateways(rows) {
      self.gateways.replace(rows);
    },

    setSensors(rows) {
      self.sensors.replace(rows);
    },

    setTemperatureSensors(rows) {
      self.temperatureSensors.replace(rows);
    },

    setLineSensors(rows) {
      // no connected_to date or it in the future
      const filtered = rows.filter(
        ({ connected_to }) => !connected_to || Date.parse(connected_to) > Date.now(),
      );

      self.lineSensors.replace(filtered);
    },

    setMultiSelect(flag) {
      self.isMultiSelect = flag;
    },

    checkSelectedLineId(id) {
      const sensor = self.getFilteredItemsByKey('sensor').find(s => s.line?.id === id);
      const bru = self
        .getFilteredItemsByKey('bru')
        .find(b => b.sensors.some(s => s.id === sensor?.id));
      const gateway = self
        .getFilteredItemsByKey('gateway')
        .find(g => g.brus.some(b => b.id === bru?.id));
      const cooler = self
        .getFilteredItemsByKey('cooler')
        .find(c => c.gateways.some(g => g.id === gateway?.id));

      const allSensors = cooler?.gateways?.flatMap(g => g?.brus?.flatMap(({ sensors }) => sensors));
      const linesIds = allSensors
        .flatMap(({ line }) => line)
        .filter(line => Boolean(line))
        .map(l => l.id);

      let updatedLineIds = [];

      if (self.selectedLineIds.some(id => !linesIds.includes(id))) {
        updatedLineIds = [id];
      } else if (self.selectedLineIds.includes(id)) {
        updatedLineIds = self.selectedLineIds.filter(_id => _id !== id);
      } else {
        updatedLineIds = [...self.selectedLineIds, id];
      }

      self.setSelectedLines(updatedLineIds);
    },

    setSelectedLines(ids) {
      self.selectedLineIds = ids;
    },

    setEstablishmentSensors(rows) {
      self.establishments_sensors = rows;
    },

    setSearchString(value) {
      self.searchString = value;
    },

    setFilters(filters) {
      self.filters = {
        ...self.filters,
        ...filters,
      };
    },
    resetFilters() {
      self.filters = topologyManagementInitialState.filters;
    },

    setIsLoaded(value) {
      self.isLoaded = value;
    },

    setSelectedGatewayID(id) {
      const idsToExclude = !id
        ? []
        : self.gateways.filter(gateway => gateway.id !== id).map(({ id }) => id);

      self.selectedGatewayID = id;
      self.filters = {
        ...self.filters,
        excludedGatewaysIds: idsToExclude,
      };
    },

    setSelectedCoolerID(id) {
      self.selectedCoolerID = id;
      self.selectedGatewayID = null;
      self.filters = {
        ...self.filters,
        excludedGatewaysIds: [],
      };
    },

    updateGateway(_gateway) {
      const index = self.gateways.findIndex(g => g.id === _gateway.id);

      if (index >= 0) {
        Object.assign(self.gateways[index], {
          ...self.gateways[index],
          latest_sample_received_at: _gateway.latest_sample_received_at,
        });
      }
    },

    handleHeartbeat(result) {
      if (!result || !Array.isArray(result) || !result.length) return;

      result.forEach(heartbeat => {
        const { sensor_id, sensor_temp_c, received_at } = heartbeat;
        const defaultSensor = self.sensors.find(s => s.id === sensor_id);
        const temperatureSensor = self.temperatureSensors.find(s => s.id === sensor_id);

        if (defaultSensor) {
          defaultSensor.latest_heartbeat_received_at = received_at;
          defaultSensor.latest_heartbeat_temperature_c = sensor_temp_c;

          const bru = self.brus.find(b => b.id === defaultSensor.bru_id);
          if (bru) {
            bru.latest_heartbeat_received_at = received_at;

            const gateway = self.gateways.find(g => g.id === bru._gateways_id);
            if (gateway) {
              gateway.latest_heartbeat_received_at = received_at;
            } else {
              console.warn(`Gateway not found for bru with _gateways_id: ${bru._gateways_id}`);
            }
          } else {
            console.warn(`BRU not found for sensor bru_id: ${defaultSensor.bru_id}`);
          }
        }

        if (temperatureSensor) {
          const gateway = self.gateways.find(g => g.id === temperatureSensor._gateways_id);

          self.temperatureSensors.replace([
            ...self.temperatureSensors.filter(e => e.id !== temperatureSensor.id),
            {
              ...temperatureSensor,
              latest_sample_received_at: received_at,
              latest_sample_value: sensor_temp_c,
            },
          ]);
          gateway.latest_sample_received_at = received_at;
        }
      });
      const sensors = self.getFilteredItemsByKey('sensor');

      const allSensorsOnline = sensors.every(sensor =>
        dateUtilities.isOnline(sensor.latest_heartbeat_received_at),
      );

      const { ui } = getRootStore();

      if (!ui.systemStatus.restored && ui.systemStatus.offline && allSensorsOnline) {
        ui.setSystemRestored(true);

        setTimeout(() => {
          ui.setSystemOffline(false);
        }, 5000);
      }
    },

    handleAddSensorSample(sample) {
      if (sample?.json) {
        const sensors = self.temperatureSensors.filter(s => s._gateways_id === sample._gateways_id);

        sensors.forEach(sensor => {
          if (sample.json[sensor._gateway_types_sensors_caption]) {
            sensor.latest_sample_received_at = sample.received_at;
            sensor.latest_sample_value = sample.json[sensor._gateway_types_sensors_caption];
          }
        });
      }
    },

    setPeriod: flow(function* (period) {
      try {
        self.period = period;
        yield self.fetchDeviceConnectivityHistory();
      } catch (e) {
        return Promise.reject(e);
      }
    }),

    resetRecentPours() {
      self.recentPours = [];
    },

    setSelectedGatewayId(id) {
      if (id !== self.selectedGatewayId) {
        self.selectedGatewayId = id;
        self.fetchDeviceConnectivityHistory();
      }
    },

    setConnectivityHistory(result) {
      const deviceTypes = ['gateways', 'brus', 'sensors'];
      self.connectivityHistory = topologyManagementInitialState.connectivityHistory;

      result.forEach(({ from_ts, to_ts, online_devices }) => {
        deviceTypes.forEach(type => {
          Object.entries(online_devices[type]).forEach(([id, isActive]) => {
            self.connectivityHistory[type].push({
              id: Number(id),
              from_ts,
              to_ts,
              isActive,
              date: from_ts,
              value: Number(isActive),
            });
          });
        });
      });

      return self.connectivityHistory;
    },

    setSensorMetaInfo(data) {
      self.selectedSensorMetadata = data;
    },
    getDeviceHistory(type, id) {
      return self.connectivityHistory[type]
        .filter(e => e.id === id)
        .sort((a, b) => Date.parse(a.from_ts) - Date.parse(b.from_ts));
    },
    getPeriodRange() {
      return differenceInDays(new Date(self.period.to), new Date(self.period.from));
    },

    getBruBySensorId(sensorId) {
      return self
        .getFilteredItemsByKey('bru')
        .find(bru => bru.sensors.some(sensor => sensor.id === sensorId));
    },

    getSensorByNestedAddress({ gatewayId, bruAddress, sensorAddress }) {
      return self
        .getFilteredItemsByKey('gateway')
        .find(gateway => gateway.id === gatewayId)
        ?.brus.find(bru => bru.gateway_bru_address === bruAddress)
        ?.sensors.find(sensor => sensor.bru_sensor_address === sensorAddress);
    },

    handleProcessPour({ pour }) {
      self.recentPours.push(pour);
      const gatewayId = +pour.gateway_id;
      const bruAddr = pour.bru_addr;
      const sensorAddr = pour.sensor_addr;

      const gateway = self.gateways.find(g => g.id === gatewayId);
      if (!gateway) return;

      const cooler = self.mappedEquipment.find(c => c.id === gateway.cooler_id);
      if (!cooler) return;

      const coolerGateway = cooler.gateways.find(g => g.id === gatewayId);
      if (!coolerGateway) return;

      const bru = coolerGateway.brus.find(
        b => b.addr === bruAddr || b.gateway_bru_address === bruAddr,
      );
      if (!bru) return;

      const sensor = bru.sensors.find(s => s.bru_sensor_address === sensorAddr);
      if (!sensor) return;

      const changedSensor = self.sensors.find(s => s.id === sensor.id);
      if (!changedSensor) return;

      changedSensor.latest_pour_id = pour.id;
      changedSensor.latest_pour_poured_at = pour.poured_at;
      changedSensor.latest_pour_received_at = pour.received_at;
    },
    updateSensorCalibrationInfo(sensorId, body) {
      const sensor = self.sensors.find(s => s.id === sensorId);
      const sensorIndex = self.sensors.findIndex(s => s.id === sensorId);

      const updatedCalibrationDetails = sensor.calibration_details
        ? { ...sensor.calibration_details, ...body }
        : body;

      self.sensors[sensorIndex].calibration_details = updatedCalibrationDetails;

      if (Object.keys(body).includes('accuracy_ratio')) {
        self.sensors[sensorIndex].latest_calibration_at = new Date().toISOString();
      }
    },

    updateLineSensor(_line_sensor) {
      if (!_line_sensor?.connected_to) {
        const index = self.lineSensors.findIndex(
          line_sensor => line_sensor?.id === _line_sensor.id,
        );

        if (index >= 0) {
          self.lineSensors.replace(
            self.lineSensors.map(lineSensor => {
              if (lineSensor.id === _line_sensor.id) {
                return { ...lineSensor, ..._line_sensor };
              }
              return lineSensor;
            }),
          );
        } else {
          self.lineSensors.replace([...self.lineSensors, _line_sensor]);
        }
      } else {
        self.lineSensors.replace(
          self.lineSensors.filter(lineSensor => lineSensor.id !== _line_sensor.id),
        );
      }
    },
  }));
