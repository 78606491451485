import { types, flow } from 'mobx-state-tree';
import groupBy from 'lodash/groupBy';
import { differenceInSeconds, max, isFuture, isPast } from 'date-fns';

import { getRootStore } from 'models/root';
import api from 'services/API';
import { LinesStatistics } from 'models/types';
import { dateUtilities, stringUtilities } from 'utils';
import { DAY_MS } from 'config/constants';

export const linesStatisticsInitialState = {
  isLoaded: false,
  all: [],
  state: 'done',
  updated: null,
  filters: {
    sortedBy: 'sort_value',
    orderBy: 'asc',
    filterBy: 'showAll',
  },
  searchString: '',
};

const LinesStatisticsWithView = LinesStatistics.views(self => ({
  get line() {
    const { linesStore } = getRootStore();

    return linesStore.getLine(self.id);
  },

  get identifier() {
    return self.identifiers.line.numerical;
  },

  get temperature() {
    const { linesStore } = getRootStore();
    const line = linesStore.getLine(self.id);

    return line?.displayedTemperature.replace(/(°F|°C)/, '');
  },

  get maxSensorTemp() {
    if (self._cleanings_latest_pours_statistics?.sensor_temp_c?.max) {
      return stringUtilities
        .makeTemperature(self._cleanings_latest_pours_statistics?.sensor_temp_c?.max)
        .replace(/(°F|°C)/, '');
    }

    return null;
  },

  get pressure() {
    const { linesStore } = getRootStore();
    const line = linesStore.getLine(self.id);

    return line?.linePressureString;
  },

  get beverageName() {
    const { linesStore } = getRootStore();
    const line = linesStore.getLine(self.id);

    return line?.beverage?._name;
  },

  get flowRate() {
    if (
      self?._pours_latest_pour?.corrected_total_ml &&
      self?._pours_latest_pour?.ts_duration &&
      self?.line?.beverage
    ) {
      if (dateUtilities.parseTimeStringToSeconds(self._pours_latest_pour.ts_duration) > 0) {
        const ml_s =
          self._pours_latest_pour.corrected_total_ml /
          dateUtilities.parseTimeStringToSeconds(self._pours_latest_pour.ts_duration);
        const oz_s = ml_s * 0.033814;
        return oz_s.toFixed(2);
      } else {
        return null;
      }
    }
    return null;
  },

  get lastPour() {
    if (self?._pours_latest_pour?.poured_at) {
      return self?._pours_latest_pour?.poured_at.replace('Z', '');
    }
    return null;
  },

  get latestCleaningDuration() {
    if (self._cleanings_cleaned_from_latest && self._cleanings_cleaned_to_latest) {
      const from = new Date(self._cleanings_cleaned_from_latest);
      const to = new Date(self._cleanings_cleaned_to_latest);

      const diff = differenceInSeconds(to, from);
      return dateUtilities.secondsToDuration(diff);
    } else {
      return null;
    }
  },

  get latestCleaningDate() {
    if (self._cleanings_cleaned_from_latest || self._cleanings_cleaned_to_latest) {
      return max([
        new Date(self._cleanings_cleaned_from_latest),
        new Date(self._cleanings_cleaned_to_latest),
      ]);
    } else {
      return null;
    }
  },

  get averageCleaningDuration() {
    if (self?._cleanings_historical_statistics?.duration.avg) {
      const seconds = dateUtilities.parseTimeStringToSeconds(
        self?._cleanings_historical_statistics?.duration.avg,
      );
      return dateUtilities.secondsToDuration(seconds);
    }
    return null;
  },

  get averageCleaningInterval() {
    if (self?._cleanings_historical_statistics?.interval?.avg) {
      const seconds = dateUtilities.parseTimeStringToSeconds(
        self._cleanings_historical_statistics.interval.avg,
      );
      return dateUtilities.secondsToDuration(seconds);
    }
    return null;
  },

  get cooler() {
    const root = getRootStore();
    return root.coolersStore.list.find(cooler => cooler.id === self.cooler_id);
  },
}));

export const linesStatisticsModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(LinesStatisticsWithView),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    filters: types.model({
      sortedBy: types.enumeration('sortedBy', [
        'sort_value',
        'beverageName',
        'temperature',
        'pressure',
        'flowRate',
        'lastPour',
        'latestCleaningDate',
        'latestCleaningDuration',
        'maxSensorTemp',
        'averageCleaningDuration',
        'averageCleaningInterval',
        'scheduled_cleaning_at',
      ]),
      orderBy: types.enumeration('orderBy', ['asc', 'desc']),
      filterBy: types.enumeration('sortedBy', [
        'showAll',
        'temperatureIssues',
        'pressureIssues',
        'inactiveLines',
        'overdueCleaning',
        'cleaningDueSoon',
      ]),
    }),
    searchString: types.string,
  })
  .views(self => ({
    get isDefaultFilters() {
      return (
        self.filters.sortedBy === 'sort_value' &&
        self.filters.orderBy === 'asc' &&
        self.filters.filterBy === 'showAll'
      );
    },
    sortedLinesById: id => {
      if (!id) {
        return [];
      }

      let ordered = self.all.filter(line => line.cooler_id === id);

      if (self.filters.filterBy === 'pressureIssues') {
        ordered = ordered.filter(({ line: { pressureCondition } }) => pressureCondition);
      }

      if (self.filters.filterBy === 'temperatureIssues') {
        ordered = ordered.filter(({ line: { temperatureCondition } }) => temperatureCondition);
      }

      if (self.filters.filterBy === 'inactiveLines') {
        ordered = ordered.filter(({ lastPour }) => Date.now() - Date.parse(lastPour) > DAY_MS);
      }

      if (self.filters.filterBy === 'overdueCleaning') {
        ordered = ordered.filter(({ scheduled_cleaning_at }) =>
          isPast(new Date(scheduled_cleaning_at)),
        );
      }

      if (self.filters.filterBy === 'cleaningDueSoon') {
        ordered = ordered.filter(
          ({ scheduled_cleaning_at }) =>
            isFuture(new Date(scheduled_cleaning_at)) &&
            Date.parse(scheduled_cleaning_at) - Date.now() < 7 * DAY_MS,
        );
      }

      ordered = ordered.sort((a, b) => {
        let aVal = a[self.filters.sortedBy];
        let bVal = b[self.filters.sortedBy];

        if (['lastPour', 'latestCleaningDate'].includes(self.filters.sortedBy)) {
          aVal = Date.parse(aVal);
          bVal = Date.parse(bVal);
        }

        if (aVal < bVal) {
          return -1;
        } else if (aVal > bVal) {
          return 1;
        } else {
          return 0;
        }
      });

      return self.filters.orderBy === 'desc' ? ordered.reverse() : ordered;
    },
    get sortedLines() {
      const { coolersStore } = getRootStore();
      let ordered = self.all.filter(el => el.status_code !== 1);

      if (coolersStore.selectedCooler?.id) {
        ordered = ordered.filter(line => line.cooler?.id === coolersStore.selectedCooler.id);
      }

      if (self.searchString) {
        const searchArray = self.searchString.toLowerCase().split(' ');
        ordered = ordered.filter(line => {
          return searchArray.every(
            element =>
              line.beverageName?.toLowerCase().includes(element) ||
              line.line_identifier?.toLowerCase().includes(element),
          );
        });
      }

      if (self.filters.filterBy === 'pressureIssues') {
        ordered = ordered.filter(({ line: { pressureCondition } }) => pressureCondition);
      }

      if (self.filters.filterBy === 'temperatureIssues') {
        ordered = ordered.filter(({ line: { temperatureCondition } }) => temperatureCondition);
      }

      if (self.filters.filterBy === 'inactiveLines') {
        ordered = ordered.filter(({ lastPour }) => Date.now() - Date.parse(lastPour) > DAY_MS);
      }

      if (self.filters.filterBy === 'overdueCleaning') {
        ordered = ordered.filter(({ scheduled_cleaning_at }) =>
          isPast(new Date(scheduled_cleaning_at)),
        );
      }

      if (self.filters.filterBy === 'cleaningDueSoon') {
        ordered = ordered.filter(
          ({ scheduled_cleaning_at }) =>
            isFuture(new Date(scheduled_cleaning_at)) &&
            Date.parse(scheduled_cleaning_at) - Date.now() < 7 * DAY_MS,
        );
      }

      ordered = ordered.sort((a, b) => {
        let aVal = a[self.filters.sortedBy];
        let bVal = b[self.filters.sortedBy];

        if (['lastPour', 'latestCleaningDate'].includes(self.filters.sortedBy)) {
          aVal = Date.parse(aVal);
          bVal = Date.parse(bVal);
        }

        if (aVal < bVal) {
          return -1;
        } else if (aVal > bVal) {
          return 1;
        } else {
          return 0;
        }
      });

      return self.filters.orderBy === 'desc' ? ordered.reverse() : ordered;
    },

    get groupedByCooler() {
      return groupBy(self.sortedLines, 'cooler.name');
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.state = 'pending';
        const response = yield api.getLinesStatistics();
        if (response?.data?.result) {
          self.all.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    resetCleaningData: flow(function* (lines) {
      try {
        const body = {
          line_ids: [...lines],
          cleaning_statistics_effective_from: new Date(),
        };
        const response = yield api.postCleanResetStatistic(body);
        if (response?.data?.result.length) {
          response?.data?.result.forEach(self.updateLine);
        }
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setFilters(filters) {
      if (!filters) {
        self.filters = {
          sortedBy: 'sort_value',
          orderBy: 'asc',
          filterBy: 'showAll',
        };
      } else {
        self.filters = {
          orderBy: filters.orderBy || self.filters.orderBy,
          sortedBy: filters.sortedBy || self.filters.sortedBy,
          filterBy: filters.filterBy || self.filters.filterBy,
        };
      }
    },
    resetFilters() {
      self.filters = { ...linesStatisticsInitialState.filters };
    },

    setSearch(value) {
      self.searchString = value || '';
    },

    updateLine(_line = {}) {
      const lineIndex = self.all.findIndex(c => c.id === _line.id);

      if (lineIndex === -1) return;

      Object.assign(self.all[lineIndex], _line);
    },

    updateLineById(id, data) {
      const lineIndex = self.all.findIndex(c => c.id === id);

      if (lineIndex === -1) return;

      Object.assign(self.all[lineIndex], data);
    },

    handleProcessPour({ pour }) {
      if (!pour) return;
      const line = self.all.find(line => line.id === pour._line_id);
      if (!line) return;

      const { poured_at, received_at } = pour;

      line._pours_latest_pour.poured_at = poured_at;
      line._pours_latest_pour.received_at = received_at;
      self.updateLine(line);
      self.updated = new Date();
    },

    handlePourRule(result) {
      const { pour_id, conditions, line_id } = result;
      const { linesStore } = getRootStore();
      const line = linesStore.getLine(line_id);

      // Exit early if no line or no latest pour is found
      if (!line?.latestPour) {
        return;
      }

      const currentConditions = line?.latestConditions || [];
      const receivedConditions = conditions.toString();
      let newConditions;

      if (!linesStore.lastPourId) {
        linesStore.setLastPourId(pour_id);
      }

      if (pour_id === linesStore.lastPourId) {
        newConditions = currentConditions.concat(Number(receivedConditions));
      } else {
        linesStore.setLastPourId(pour_id);
        newConditions = conditions;
      }

      self.updateLine({
        ...line,
        _pours_latest_pour: {
          ...line.latestPour,
          conditions: newConditions,
        },
      });
    },
  }));
