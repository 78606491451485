import React from 'react';
import { Box, Slider, Typography, styled } from '@mui/material';

import { useStore } from 'hooks';

const DELTA = 15; // offset of min/max values

const AlertSlider = ({
  className,
  isAbsolute = false,
  value,
  defaultValue = 50,
  min = -20,
  max = 100,
}) => {
  const { establishmentStore } = useStore();
  const temperatureUnit = establishmentStore.temperatureUnit;
  const minLimit = Math.ceil(Math.min(value, min) - DELTA);
  const maxLimit = Math.ceil(Math.max(value, max) + DELTA);

  const low = Math.round((min * 100) / maxLimit);
  const high = Math.round((max * 100) / maxLimit);

  const absoluteThumbValue = () => {
    if (value >= max) {
      return '85%';
    } else if (value <= min) {
      return '15%';
    } else {
      return '50%';
    }
  };

  return (
    <StyledRootBox className={className}>
      <Slider
        disabled
        min={minLimit}
        max={maxLimit}
        track={false}
        defaultValue={defaultValue}
        value={Number.isNaN(+value) ? defaultValue : value}
        valueLabelFormat={Number.isNaN(+value) ? 'N/A' : `${value?.toFixed(1)} ${temperatureUnit}`}
        className="slider"
        valueLabelDisplay="on"
        sx={{
          ...(isAbsolute && {
            '& .MuiSlider-thumb': {
              left: `${absoluteThumbValue()} !important`,
            },
          }),
          '& .MuiSlider-rail': {
            opacity: 1,
            background: isAbsolute
              ? `linear-gradient(to right, #2196F3 0% ${32}%, transparent ${32}% ${33}%, #66BB6A ${33}% ${65}%, transparent ${65}% ${66}%, #FF7A7A ${66}% 100%)`
              : `linear-gradient(to right, #2196F3 0% ${low}%, transparent ${low}% ${
                  low + 1
                }%, #66BB6A ${low + 1}% ${high - 1}%, transparent ${
                  high - 1
                }% ${high}%, #FF7A7A ${high}% 100%)`,
          },
        }}
      />
      <Box className="alert_slider_description_box">
        <Typography variant="body2">Low</Typography>
        <Typography variant="body2">
          Normal ({min?.toFixed(1) || 'N/A'}
          {temperatureUnit} - {max?.toFixed(1) || 'N/A'}
          {temperatureUnit})
        </Typography>
        <Typography variant="body2">High</Typography>
      </Box>
    </StyledRootBox>
  );
};

export default AlertSlider;

const StyledRootBox = styled(Box)(() => ({
  width: '100%',

  '.alert_slider_description_box': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '&.center': {
      justifyContent: 'center',
    },
  },

  '.slider': {
    padding: '6px 0px',

    '& .MuiSlider-thumb': {
      width: '2px',
      color: '#FFFFFF',
      borderRadius: 0,

      boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
      },
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
    },

    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      padding: '0px 5px',
      border: '1px solid #FF7A7A',
      '&::before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        // color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
  },
}));
