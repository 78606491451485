import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Drawer,
  List,
  ListItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';

import { useMediaQueries, useStore } from 'hooks';
import { NotFoundIcon } from 'assets/icons';
import FullDetails from 'components/@SystemConfiguration/Subscription/SubscriptionDetailsModal/FullDetails';
import { text, details } from 'components/@SystemConfiguration/Subscription/settings';

const smartMonitoringBlockedRoutes = [
  '/on-tap',
  '/inventory',
  '/inventory/cooler',
  '/inventory/ordering',
  '/inventory/history',
  '/my-beverages',
  '/quality-management/cleaning-management',
  '/quality-management/cleaning-logs',
  '/quality-management/draft-system-configuration',
  '/reports',
  '/reports/generate',
  '/reports/pos-skus',
  '/reports/configuration',
  '/digital-menu',
  '/digital-menu/menus',
  '/digital-menu/lists',
  '/digital-menu/designs',
  '/activity-logs',
];

const noSubscriptionsBlockedRoutes = [
  ...smartMonitoringBlockedRoutes,
  '/quality-management',
  '/quality-management/system-health',
  '/employees',
  '/system-configuration/general',
  '/system-configuration/topology-management',
  '/system-configuration/alerts',
];

const SubscriptionDetailsModal = () => {
  const { subscriptionsStore, userStore } = useStore();
  const { isTabletOrSmaller } = useMediaQueries();
  const navigate = useNavigate();
  const location = useLocation();
  const [openFullDetails, setOpenFullDetails] = useState(false);
  const [openSubscriptionBlockedModal, setOpenSubscriptionBlockedModal] = useState(false);
  const [firstAttempt, setFirstAttempt] = useState(true);

  const hasPermission = ['BarTrack', 'Owner', 'Manager'].includes(
    userStore.currentRole?._role_name,
  );

  const handleMoreDetails = () => {
    if (isTabletOrSmaller) {
      hasPermission ? setOpenFullDetails(true) : navigate('/system-configuration/subscription');
    } else {
      hasPermission
        ? navigate('/system-configuration/subscription/details', {
            state: { type: 'smartDraft' },
          })
        : navigate('/system-configuration/subscription');
    }
  };

  const handleClose = () => {
    hasPermission
      ? navigate('/system-configuration/subscription')
      : navigate('/quality-management');
  };

  useEffect(() => {
    const { isDraftActive, isSmartMonitoringActive } = subscriptionsStore;

    if (
      !isDraftActive &&
      !isSmartMonitoringActive &&
      noSubscriptionsBlockedRoutes.includes(location.pathname)
    ) {
      setOpenSubscriptionBlockedModal(true);
    } else if (
      !isDraftActive &&
      isSmartMonitoringActive &&
      smartMonitoringBlockedRoutes.includes(location.pathname)
    ) {
      setOpenSubscriptionBlockedModal(true);
      if (firstAttempt) {
        navigate('/quality-management');
        setFirstAttempt(false);
      }
    } else {
      setOpenSubscriptionBlockedModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, subscriptionsStore, firstAttempt]);

  const body =
    !subscriptionsStore.isDraftActive && !subscriptionsStore.isSmartMonitoringActive ? (
      <StyledBox sx={({ spacing }) => ({ padding: spacing(5) })}>
        <Box className="icon_box">
          <NotFoundIcon className="spaceman_icon" />
        </Box>
        <Typography sx={{ margin: '32px 0' }} align="center" variant="h4">
          No Valid Subscription Found!
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: '16px',
            fontWeight: 500,
            color: palette.whiteEmphasis.high,
          })}
        >
          It looks like you don't have a valid subscription. To access all features, please choose
          one of our subscription plans:
        </Typography>

        <List className="list">
          <ListItem>
            <Typography>
              <b>Smart Draft:</b> Monitor and manage your draft system with real-time analytics,
              including line cleanliness, temperature, and flow rates.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <b>Smart Cooling:</b> Optimize your cooling systems with detailed monitoring of
              refrigeration units, kegerators, and glycol power packs. Ensure consistent performance
              and efficiency.
            </Typography>
          </ListItem>
        </List>

        <Typography
          sx={({ palette }) => ({
            fontSize: '16px',
            fontWeight: 500,
            color: palette.whiteEmphasis.high,
          })}
        >
          Please select a plan to continue enjoying our full range of services.
        </Typography>

        <Box className="controls no_subscription">
          <Button
            variant="contained"
            onClick={() => navigate('/system-configuration/subscription')}
          >
            See subscriptions
          </Button>
        </Box>
      </StyledBox>
    ) : (
      <StyledBox>
        <Box className="image" sx={{ backgroundImage: `url(${details.smartDraft.image})` }} />
        <Box className="body">
          <Box className="title_box">
            <Typography component="span" className="title">
              {subscriptionsStore.subscriptionNameByType('smartDraft')}{' '}
            </Typography>
            <Typography component="span" className="title" sx={{ fontWeight: '500 !important' }}>
              subscription required
            </Typography>
          </Box>
          <Typography className="description">{text.smartDraft?.details?.description}</Typography>
        </Box>
        <Box className="controls">
          <Button variant="contained" onClick={handleMoreDetails}>
            More Details
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Dismiss
          </Button>
        </Box>
      </StyledBox>
    );

  return isTabletOrSmaller ? (
    <StyledRootDrawer open={openSubscriptionBlockedModal} onClose={handleClose} anchor="bottom">
      {body}
      <FullDetails
        open={openFullDetails}
        type={'smartMonitoringCooling'}
        onClose={() => setOpenFullDetails(false)}
      />
    </StyledRootDrawer>
  ) : (
    <StyledDialog
      container={() => document.getElementById('main')}
      open={openSubscriptionBlockedModal}
      onClose={handleClose}
      fullWidth
      disableAutoFocus={true}
      slotProps={{
        root: {
          sx: { position: 'absolute', height: 'calc(100vh - 100px)' },
        },
        container: {
          sx: { position: 'absolute' },
        },
        backdrop: {
          sx: { position: 'absolute' },
        },
      }}
    >
      <DialogContent>{body}</DialogContent>
    </StyledDialog>
  );
};

export default SubscriptionDetailsModal;

const StyledDialog = styled(Dialog)(({ theme: { spacing, palette } }) => ({
  '& .MuiDialogContent-root': {
    padding: '0 !important',
    backgroundColor: palette.secondary.greyLight,
  },
}));

const StyledRootDrawer = styled(Drawer)(({ theme: { palette } }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: palette.secondary.greyLight,
    borderRadius: '16px 16px 0 0',
  },
}));

const StyledBox = styled(Box)(({ theme: { spacing, palette, breakpoints } }) => ({
  '.image': {
    width: '100%',
    height: '256px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundColor: '#141414',

    [breakpoints.down('md')]: {
      height: '166px',
      backgroundSize: 'contain',
    },
  },

  '.icon_box': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: spacing(2),
  },

  '.spaceman_icon': {},

  '.list': {
    listStyleType: 'disc',
    paddingLeft: spacing(2),

    '& > li': {
      display: 'list-item',
      paddingLeft: 0,

      '& > p': {
        fontSize: '16px',
        fontWeight: 500,
        color: palette.whiteEmphasis.high,
      },
    },
  },

  '.body': {
    padding: spacing(4),

    [breakpoints.down('md')]: {
      padding: spacing(2),
    },

    '.title_box': {
      marginBottom: spacing(2),
    },

    '.title': {
      fontSize: '26px',
      fontWeight: 700,
      color: palette.whiteEmphasis.high,

      [breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    '.description': {
      fontSize: '16px',
      fontWeight: 500,
      color: palette.whiteEmphasis.high,
    },
  },

  '.controls': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing(2),
    alignItems: 'center',
    marginBottom: spacing(4),

    '&.no_subscription': {
      marginBottom: 0,
      marginTop: spacing(2),
    },

    [breakpoints.down('md')]: {
      padding: spacing(2),
      marginBottom: spacing(0),
    },

    button: {
      textTransform: 'none',
      width: '300px',
      [breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  '.hidden': {
    display: 'none',
  },
}));
