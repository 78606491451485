import React, { useState, useMemo, useContext } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Box, Button, Slider, TextField, Typography, styled } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { useStore, useToast } from 'hooks';
import { messages } from 'config/messages';
import { CalibrationContext, flowVolumeStatusMap } from 'context/calibration';

const PourDetected = ({ sensor, bru, detectedPour }) => {
  const {
    setIsFlowVolumeCalibrated,
    power,
    noise,
    flowVolumeCalibrationAttempt,
    accuracy,
    handleAccuracy,
    setFlowVolumeCalibrationAttempt,
    onSuccessfulCalibration,
  } = useContext(CalibrationContext);
  const { topologyManagementStore } = useStore();
  const { errorToast } = useToast();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDebounced = useDebouncedCallback(value => {
    handleAccuracy(value);
  }, 1000);

  const min = 91;
  const max = 109;
  const lowerBound = 98;
  const upperBound = 102;

  const lowerPercent = ((lowerBound - min) / (max - min)) * 100;
  const upperPercent = ((upperBound - min) / (max - min)) * 100;

  const linearStyle = useMemo(() => {
    const red = '#FF7A7A';
    const green = '#66BB6A';
    const gray = '#6E6E6E';

    const color = accuracy?.value ? [red, green, red] : [gray, gray, gray];

    return `linear-gradient(to right, ${color[0]} 0% ${lowerPercent}%, transparent ${lowerPercent}% ${lowerPercent}%, ${color[1]} ${lowerPercent}% ${upperPercent}%, transparent ${upperPercent}% ${upperPercent}%, ${color[2]} ${upperPercent}% 100%)`;
  }, [accuracy, lowerPercent, upperPercent]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const gw = topologyManagementStore
        .getFilteredItemsByKey('gateway')
        .find(gw => gw.id === sensor._gateways_id);

      const newAccuracyRatio = Number((detectedPour?.corrected_total_ml).toFixed(2) / value);

      const status = await topologyManagementStore.calibrateSensorAccuracy({
        gateway_identifier: gw.identifier,
        gateway_bru_address: bru.gateway_bru_address,
        bru_sensor_address: sensor.bru_sensor_address,
        accuracy_ratio: newAccuracyRatio,
        power: power.value || 1,
        signal: noise.value,
        calibration_attempts: flowVolumeCalibrationAttempt + 1,
      });

      if (['CALIBRATED'].includes(flowVolumeStatusMap[status])) {
        setIsFlowVolumeCalibrated(true);
        setFlowVolumeCalibrationAttempt(0);
      } else if (['CALIBRATED_WITH_ISSUES'].includes(flowVolumeStatusMap[status])) {
        setIsFlowVolumeCalibrated(true);
        setFlowVolumeCalibrationAttempt(prevState => prevState + 1);
      } else {
        setIsFlowVolumeCalibrated(false);
      }

      onSuccessfulCalibration({ accuracy_ratio: newAccuracyRatio });
    } catch (error) {
      errorToast(messages.sensor.CALIBRATE_ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledRootBox>
      <Box className="title_wrapper">
        <Typography className="title">Pour Detected:</Typography>
        <Typography className="title detected_value">
          {detectedPour ? Number(detectedPour.corrected_total_ml).toFixed(1) : 'N/A'} mL
        </Typography>
      </Box>

      <Typography className="subtitle">
        Place your filled glass on the scale, the displayed value shows how much volume was poured.
        Please enter that number into the field below (1g = 1mL).
      </Typography>
      <Box className="common_box input_box">
        <TextField
          className="input_value"
          type="number"
          value={value}
          onChange={({ target: { value } }) => {
            setValue(value);
            handleDebounced(value);
          }}
          disabled={loading}
        />
      </Box>
      <Box className="common_box accuracy_box">
        <Box className="info_wrapper">
          <Typography>Sensor Reading</Typography>
          <Typography
            className={clsx({
              green: accuracy.level === 'Good',
              red: accuracy.level === 'Low' || accuracy.level === 'High',
            })}
          >
            {accuracy.level}
          </Typography>
        </Box>

        <Slider
          className={clsx('slider', {
            disabled: !accuracy?.percentage,
          })}
          disabled={!accuracy?.percentage}
          min={91}
          max={109}
          track={false}
          value={accuracy?.percentage || 100}
          valueLabelDisplay="on"
          valueLabelFormat={() => (accuracy?.percentage ? `${accuracy.percentage}%` : 'N/A')}
          sx={{
            '& .MuiSlider-rail': {
              opacity: 1,
              background: linearStyle,
            },
            '& .MuiSlider-thumb': {
              width: '2px',
            },
          }}
        />
      </Box>

      <Box className="action_box">
        <Button
          variant={'contained'}
          onClick={handleSubmit}
          color="primary"
          disabled={loading || !accuracy?.value}
        >
          Continue
        </Button>
      </Box>
    </StyledRootBox>
  );
};

export default observer(PourDetected);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',

  '.title_wrapper': {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 24px',
    alignItems: 'center',

    '.title': {
      fontSize: '20px',
      fontWeight: '500',
      textAlign: 'left',
      color: palette.whiteEmphasis.high,

      '&.detected_value': {
        backgroundColor: '#252525',
        borderRadius: '4px',
        border: `1px solid #313131`,
        padding: '4px',
        marginLeft: '8px',
      },
    },
  },

  '.subtitle': {
    margin: '0 24px',
    fontSize: '12px',
    textAlign: 'center',
    color: palette.whiteEmphasis.high,
  },

  '.common_box': {
    width: '100%',
    padding: '20px',
    margin: '12px 0',
    border: `1px solid #313131`,
    backgroundColor: palette.secondary.greyLight,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&.input_box': {
      height: '112px',
      '.input_value': {
        width: '160px',
        height: '48px',
        backgroundColor: palette.background.main,
        borderRadius: '5px',
        border: `1px solid ${palette.primary.main}`,
      },
    },

    '&.accuracy_box': {
      height: '88px',
      flexDirection: 'row',

      '.info_wrapper': {
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'space-between',
        width: '50%',
      },

      '.slider': {
        width: '50%',
      },
    },
  },

  '.green': {
    color: palette.status.green,
  },

  '.red': {
    color: palette.status.red,
  },

  '.action_box': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '24px 0',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid #313131`,

    button: {
      textTransform: 'none',
      width: 'calc(100% - 40px)',
      height: '40px',
    },
  },
}));
