import React from 'react';
import clsx from 'clsx';
import { Box, Typography, Button, styled } from '@mui/material';

import { ArrowForward, ReportProblemOutlined } from '@mui/icons-material';

import AlertSlider from 'components/shared/AlertSlider';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { dateUtilities } from 'utils';
import { useStore } from 'hooks';

const AlertBox = ({ notification, isAbsolute = false, onViewSensorDataClick }) => {
  const {
    _alarms_alert_caption,
    _coolers_name,
    _conditions_happened_from,
    _conditions_latest_trigger_at,
    _gateways_name,
    _gateways_id,
    alarm,
    condition,
    criteriaValue,
    min,
    max,
  } = notification;
  const { deviceHistoryStore } = useStore();

  const fetchSensorData = () => {
    deviceHistoryStore.setSelectedGatewayId(_gateways_id);
    const { from, to } = dateUtilities.getDatesByPeriod('custom', {
      fromDate: _conditions_happened_from || _conditions_latest_trigger_at,
      toDate: _conditions_latest_trigger_at || _conditions_happened_from,
    });
    deviceHistoryStore.setPeriod({
      from,
      to,
    });
    // deviceHistoryStore.fetchDataset();
    onViewSensorDataClick();
  };

  return (
    <StyledRootBox>
      <Box
        className={clsx('alert_wrapper', {
          active: !condition?._closed,
          resolved: condition?._closed,
        })}
      >
        <Box className="wrapper_main_box">
          <Box className="icon_wrapper">
            {!condition?._closed ? (
              <ReportProblemIcon className="alert_icon active" />
            ) : (
              <ReportProblemOutlined className="alert_icon resolved" />
            )}
          </Box>

          <Box className="main_box">
            <Box className="info_box">
              <Box className="title_box desktop_only">
                <Typography variant="title desktop_only">
                  {_alarms_alert_caption + ' Alert'}
                </Typography>
                <Typography
                  className={clsx('status', {
                    active: !condition?._closed,
                    resolved: condition?._closed,
                  })}
                >
                  {condition?._closed ? 'Resolved' : 'Active'}
                </Typography>
              </Box>
              <Typography variant="body2" className="desktop_only">
                {_coolers_name} - {alarm?.metadata?.original_sensor_name}
              </Typography>
              <Typography variant="body2">
                Issue Started -{' '}
                <span className="white_text">
                  {dateUtilities.formatDateIncludingToday(_conditions_happened_from)}
                </span>
              </Typography>
              <Typography variant="body2">
                Latest Trigger -{' '}
                <span className="white_text">
                  {dateUtilities.formatDateIncludingToday(_conditions_latest_trigger_at)}
                </span>
              </Typography>
            </Box>
            <Box className="slider_box">
              <AlertSlider isAbsolute={isAbsolute} value={criteriaValue} min={min} max={max} />
            </Box>

            <Box className="mobile_only">
              <Typography variant="title">{_coolers_name}</Typography>
              <Typography variant="body2">{_gateways_name}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className="action_box">
          <Button className="view_button" endIcon={<ArrowForward />} onClick={fetchSensorData}>
            View sensor data
          </Button>
        </Box>
      </Box>
    </StyledRootBox>
  );
};

export default AlertBox;

const StyledRootBox = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  borderRadius: '16px',
  [breakpoints.up('sm')]: {
    background: palette.elevation.overlay3,
  },
  '.icon_wrapper': {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '.alert_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '401px',
    height: '272px',
    borderRadius: '16px',
    padding: '24px',
    justifyContent: 'space-between',
    '&.active': {
      border: `1px solid ${palette.status.red}`,
    },
    [breakpoints.down('sm')]: {
      all: 'unset',
      '&.active': {
        border: 'none',
      },
    },
  },

  [breakpoints.down('sm')]: {
    border: 'none',
    padding: '16px',
  },

  '.alert_icon': {
    color: palette.whiteEmphasis.high,
    width: '24px',
    height: '24px',
    marginRight: '14px',
    marginTop: '4px',
    '&.active': {
      color: palette.status.red,
    },
    '&.resolved': {
      color: palette.whiteEmphasis.medium,
    },
    [breakpoints.down('sm')]: {
      width: '48px',
      height: '48px',
    },
  },

  '.wrapper_main_box': {
    display: 'flex',
  },

  '.main_box': {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: '100%',

    [breakpoints.down('sm')]: {
      gap: '12px',
      alignItems: 'center',
    },

    '.info_box': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',

      [breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },

    '.slider_box': {
      width: '100%',

      [breakpoints.down('sm')]: {
        marginTop: '16px',
      },
    },
  },

  '.status': {
    borderRadius: '36px',
    fontSize: '12px',
    padding: '2px 4px',

    '&.active': {
      color: palette.status.red,
      border: `1px solid ${palette.status.red}`,
    },

    '&.resolved': {
      color: palette.status.green,
      border: `1px solid ${palette.status.green}`,
    },

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  '.action_box': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '12px',
  },

  '.view_button': {
    borderRadius: '4px',
    textTransform: 'none',
    display: 'flex',
    margin: '0 auto',
    padding: '12px 14px 12px 24px',
    color: '#ffffff99',
    border: `1px solid ${palette.primary.main}`,
    svg: {
      fontSize: '17px',
      marginBottom: '2px',
    },
  },

  '.white_text': {
    color: palette.whiteEmphasis.high,
  },

  '.desktop_only': {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  '.mobile_only': {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  '.title_box': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
